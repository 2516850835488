.flex-wrap__container {
  margin: 0 auto 60px auto;
  width: 100%;
  max-width: 1240px;
}
.flex-wrap__inner {
  display: flex;
  flex-wrap: wrap;
  @media(min-width: 1300px) {
    padding: 0;
  }
}
.integrations .search__section {
  display: none;
}
// PAGE ARTICLE
.article__outer {
  position: relative;
  @media(min-width: 768px) {
    padding-bottom: 60px;
  }
}
.article__main-wrapper {
  margin: 90px auto 0 auto;
  max-width: 1280px;
  @media(min-width: 768px) {
    margin: 90px auto;
  }
  &--blog {
    margin: 40px auto 0 auto;
    @media(min-width: 768px) {
      margin: 90px auto 0 auto;
    }
  }
}
.article__intro-slider {
  margin: 20px auto;
}
.column-left {
  margin: 40px auto 0 auto;
  @media(min-width: 768px) {
    margin: 0;
    width: 160px;
  }
  &:empty {
    margin: 0;
  }
}
.article__main-inner--bottom .column-left {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  order: 1;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 -5px 20px rgba(0,0,0,.2);
  z-index: 2;
  @media(min-width: 768px){
    position: unset;
    padding-bottom: 16px;
    order: 0;
    width: 160px;
    background-color: transparent;
    box-shadow: none;
  }
}
.article__header {
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  width: 100%;
  max-width: 992px;
  @media(min-width: 768px) {
    padding: 0;
  }
  section {
    @media(min-width: 768px){
      padding: 0 16px;
    }
  }
}
.article__header .titresection {
  text-align: left;
}
.keywords-editable__section {
  display: flex;
  justify-content: flex-start;
  margin-left: -8px;
  flex-wrap: wrap;
  @media(min-width: 768px){
    margin-left: 8px;
  }
  section {
    padding: 0 8px;
  }
}
.article__main-inner {
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    flex-direction: row;
    width: 100%;
  }
  &--slider {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.article__container {
  width: 100%;
  max-width: 992px;
}
.article__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  section:not(.image-article):not(.button-article){
    padding: 0 10px;
    width: 100%;
    @media(min-width: 768px){
      padding: 0 16px;
    }
  }
}
.button-article {
  display: flex;
  margin-right: 8px;
  margin-left: 8px;
  flex: 1 1 calc(~"50% - 16px");
  &--forum {
    justify-content: flex-end;
  }
}
.button-article .btn__link {
  min-width: 250px;
  max-width: 300px;
  text-align: center;
  @media(min-width: 620px){
    margin: 45px 0;
  }
}
.article__main-wrapper--blog .button-article--forum {
  flex: 1 1 100%;
  justify-content: center;
  .btn__link {
    margin: 25px auto 60px;
  }
}
.article-slider {
  display: flex;
  //margin: 0 auto 50px auto;
  margin-bottom: 0px;
  max-width: 250px;
  align-items: stretch;
  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  @media(min-width: 400px) {
    max-width: 296px;
  }
  @media(min-width: 540px) {
    max-width: 480px;
  }
  @media(min-width: 640px) {
    max-width: 490px;
  }
  @media(min-width: 700px) {
    max-width: 612px;
  }
  @media(min-width: 880px) {
    max-width: 780px;
  }
  @media(min-width: 992px) {
    margin-top: 30px;
    max-width: 890px;
    overflow: hidden;
  }
  @media(min-width: 1120px) {
    max-width: 918px;
  }
  @media(min-width: 1200px) {
    max-width: 1080px;
  }
  @media(min-width:1300px) {
    max-width: 1200px;
  }
  @media(min-width:1400px) {
    max-width: 1311px;
  }
}
.article-slider .multi-slide {
  padding: 0 5px;
  max-width: 250px;
  @media(min-width: 400px) {
    max-width: 296px;
  }
  @media(min-width: 540px) {
    max-width: 240px;
  }
  @media(min-width: 640px) {
    margin: 0;
    padding: 0 5px;
    max-width: 245px;
  }
  @media(min-width: 700px) {
    max-width: 306px;
  }
  @media(min-width: 880px) {
    padding: 0 8px;
    max-width: 260px;
  }
  @media(min-width: 992px) {
    padding: 0 10px;
    max-width: 296px;
  }
  @media(min-width: 1120px) {
    max-width: 306px;
  }
  @media(min-width: 1200px) {
    max-width: 270px;
  }
  @media(min-width: 1300px) {
    max-width: 300px;
  }
  @media(min-width: 1400px) {
    max-width: 306px;
  }
}
.cms--editing .article-slider {
  overflow: visible;
}