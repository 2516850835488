.home .mavvideo {
  position: relative;
  padding-bottom: 44.4791667%;
  margin: 0 auto;
  height: 0;
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: center;
}
.bandeau-home {
  position: relative;
/*  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 11%;
    height: 100%;
    background: rgb(29,29,38);
    background: linear-gradient(-90deg, rgba(29,29,38,0) 0%, rgba(29,29,38,1) 5%);
    content: '';
    z-index: 1;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 11%;
    height: 100%;
    background: rgb(29,29,38);
    background: linear-gradient(90deg, rgba(29,29,38,0) 0%, rgba(29,29,38,1) 5%);
    content: '';
  }*/
}
.bandeau-home {
  .mavvideo iframe,
  .mavvideo embed,
  .mavvideo object,
  .mavvideo video {
    @media (min-width: @screen-sm) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
    @media (min-width: @screen-md) {
      margin-top: 0;
    }
  }
}

.home .mavvideo img {
  position: absolute;
  top: 0;
  width: 100%;
}