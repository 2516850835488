
.account-box {
  width: 100%;
  max-width: 500px;
  margin: 50px auto 50px auto;

  .panel-info .panel-body a.signup-link {
    color: @mineure;
    transition: color 160ms @transition-easing;
    &:hover {
      color: @majeure;
    }
  }
}

.account-box .panel-info,
#account-content .panel-info,
#licence-content .panel-info {
  background-color: @majeure;
  border-color: @majeure;
  color: #333;
  > .panel-heading {
    color: white;
    background-color: @majeure;
    font-weight: bold;
    border-color: @majeure;
    padding: 13px 15px;
  }
  .panel-body {
    position: relative;
    background-color: white;
    .border-bottom-radius(3px);
    .form-group {
      margin: 0 0 20px 0;
      label {
        font-weight: normal;
        color: @grismoyen;
      }
      &.has-error label {
        color: @majeure;
      }
      .help-block {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 0px;
      }
    }
    .new-license {
      margin-bottom: 30px;
    }
    .btn.btn-danger {
      padding: 4px 15px;
      border-color: @majeure;
      transition: background-color 160ms @transition-easing;
      &:hover {
        color: white;
        background-color: @mineure;
        border-color: @mineure;
      }
    }
    hr {
      border-color: #888;
    }
    p {
      color: #262626;
      a {
        // color: @grisclair;
      }
    }
    a:not(.select2-choice):not(.signup-link) {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  button,
  a.btn {
    transition: all 160ms @transition-easing;
    > .glyphicon-refresh {
      color: white;
      top: 3px;
      margin-right: 3px;
      display: none;
    }
    &.disabled {
      > .glyphicon-refresh {
        display: inline-block;
        &.rotating {
          animation-name: spin;
          animation-duration: 1000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }
}
#account-content .panel-info .panel-body a.btn.btn-freeze {
  color: @mineure;
}
.col-sm-8.radio-inline__container {
  padding-top: 14px;
}
.form-group {
   input[type="text"] {
     color: @majeure;
    background-color: white!important;
  }
}
#login-username::placeholder {
  color: @majeure;
  background-color: white;
}
.form-horizontal .control-label {
  padding-top: 14px;
  padding-right: 10px;
  width: 20%;
}

#licence-content {
  width: 720px;
  float: left;
  div.panel:not(.panel-disabled) {
    div.panel-title {
      cursor: pointer;
    }
  }
}

.alert-danger {
  color: white!important;
  background-color: @mineure;
  border-color: @mineure;
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
.has-error .input-group-addon {
  color: white!important;
  background-color: @majeure;
  border-color: @majeure;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#account-nav {
  background-color: @majeure;
  border-radius: 4px;
  margin-right: 20px;
  padding: 0 10px;
  height: 100%;


  @media(min-width: 768px) {
    width: 190px;
    float: left;
    margin-left: 20px;
    height: 100%;
  }
  @media(max-width: 767px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  > li {
    &.current > a {
      border-left: 3px solid @mineure;
      border-radius: 0;
      color: @mineure;
      > span {
        color: @mineure;
      }
      &:hover {
        color: @mineure;
        background-color: transparent;
      }
    }
    > a {
      padding: 15px 10px;
      line-height: 14px;
      color: white;
      transition: background-color 160ms @transition-easing, color 160ms @transition-easing;
      &:hover,
      &:focus {
        background-color: @grisbleufonce;
        color: white;
      }
      > span {
        margin-right: 10px;
      }
    }
    &.brand {
      color: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid #aeaeae;
      > a {
        color: white;
      }
      > a span {
        color: white;
      }
      > a:hover,
      > a:focus,
      > a:hover span,
      > a:focus span {
        cursor: default;
        color: #fff;
      }
    }
  }
}
.contenu.page-license {
  margin: 30px auto;
}
#account-content {
  @media(min-width: 768px) {
    width:720px;
    float: left;
  }
  @media(max-width: 767px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 24px;
  }
  .panel-subtitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    .panel-subtitle-text {
      flex-wrap: wrap;
      flex: 1 1 0;
      align-self: flex-start;
    }
    .panel-subtitle-btn {
      align-self: flex-end;
    }
    .btn {
      min-width: 111px;
    }
  }
  .form-control {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    width: 100%;
    max-width: 300px;
  }
  #coupon.form-control {
    width:auto;
  }
  .form-group {
    display: block;
  }
  #s2id_country {
    max-width: 300px;
    width: 100%;
  }
  #s2id_currency {
    width: 100px;
  }
}

#basket-collapse {
  .licence-type-table {
    padding: 0;

    //.early_access {
    //  position: absolute;
    //  top: 12px;
    //  left: -3px;
    //}
    .licence-price {
      width: 26%;
    }
    .licence-quantity {
      width: 29%;
    }
    .licence-fixed-quantity {
      width: 20%;
      padding-right: 20px;
    }
    .vat-warning {
      padding-top: 10px;
    }
  }
  .panel-body {
    @media(max-width: 400px) {
      padding: 10px;
    }
    .licence-price {
      @media(max-width: 400px) {
        font-size: 14px;
      }
    }
    .licence-name {
      @media(max-width: 400px) {
        font-size: 14px;
      }
      .early_access {
        @media(max-width: 400px) {
          transform: scale(0.75,0.75);
          //top: 6px;
          //left: -12px;
        }
      }
    }
  }
}
.panel {
  margin-bottom: 0;
}
ul.licence-list {
  list-style: none;
  margin-left: 0;
  padding: 0 20px;
  color: @grismoyen;
  li {
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
    line-height: 25px;
    border-width: 0 0 1px 0;
    padding: 10px 15px;
    background: -webkit-gradient(
      radial,
      50% 10%,
      0,
      50% 50%,
      320,
      from(#C5C5C5),
      to(#C5C5C5)
    );
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    > div {
      width: 100%;
    }
    div.manage-container {
      display: flex;
      flex-direction: row-reverse;
    }
    > span.license {
      //float: left;
      width: 50%;
      > span.licence-number {
        font-size: 11px;
      }
      > span.licensed-to {
        font-size: 11px;
        form {
          display: inline-block;
          input {
            width: 80px;
            margin: 0;
            background: rgba(0, 0, 0, 0);
            border: none;
            line-height: 1;
            font-family: inherit;
            outline: none;
            padding: 0;
            cursor: pointer;
            &:focus {
              cursor: text;
              border-bottom: 1px dotted #5b5b78;
            }
          }
        }
      }
    }
    > span.switch-toggle {
/*      float: right;
      margin-top: 20px;*/
      width: 50%;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0;
      text-align: right;
      .btn-upgrade-license {
        &.active {

          background-color: @majeure;
          border-color: #ADADAD;
          > span {
            color: #ffffff !important;
          }
        }
        font-size: 14px;
        font-family: 'Nexa-Book', sans-serif;
        width: 144px;
        height: 36px;
      }
    }
    > span.details {
      float: left;
      text-align: left;
      > i {
        font-size: 12px;
      }
      .glyphicon {
        font-size: 15px;
        margin-left: 3px;
        cursor: pointer;
        color: black;
        &:hover {
          color: @majeure;
        }
      }
    }
    &:hover,
    &.current {
      border-left: 3px solid @majeure;
      padding: 10px 15px 10px 12px;
    }
    &:last-child {
      background: none;
    }
  }
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  color: @gristitle;
}
#loginform .input-group,
#forgotform .input-group,
#profile-form .input-group {
  width: 70%;
  input {
    border: 0.25px solid rgba(0,0,0,.075);
  }
}
.input-group-addon {
  width: 45px;
  border: none;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  background-color: @majeure;
  .glyphicon {
    color: white;
  }
}
#account-wrapper {
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;
}

.panel-cart {
  .panel-subtitle {
    background: #efefef;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    .badge {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }

  .panel-body {
    border-bottom: 1px solid #ddd;
    border-radius: 0 !important;
    width: 100%;
    &.disabled > .filter {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #ddd;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

#payment-collapse {
  .tax {
    border: none;
    padding: 0;
    color: #aeaeae;
  }
  .amount, .volume-discount-amount {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #aeaeae;
  }
  .volume-discount-amount {
    padding-top: 0px;
  }
  .pull-right {
    margin-top: 0;
  }
}

div.licence-type-table {
  margin-left: 0;
  padding: 0 5px;
  color: @grismoyen;
  width: 100%;
  > div.licence {
    line-height: 1;
    vertical-align: middle;
    padding: 10px 0px;
    border: dotted #e5e5e5;
    border-width: 3px 0 0 0;
    .pull-right {
      margin-top: 0;
    }
    &:first-child {
      display: flex;
      align-items: center;
      border-width: 0;
    }
    &.tax {
      border: none;
      padding: 0;
      color: #9f9f9f;
    }
    &.amount, &.volume-discount-amount {
      border: none;
      padding-bottom: 10px;
      padding-top: 10px;
      color: #9f9f9f;
    }
    &.volume-discount-amount {
      padding-top: 0px;
    }

    > div {
      float: left;
      &.licence-name {
        width: 45%;
        position: relative;
        //> span.early_access {
        //  background-image: url(../images/early_access.png);
        //  display: inline-block;
        //  width: 110px;
        //  height: 34px;
        //  position: absolute;
        //  top: -8px;
        //  right: 50px;
        //
        //}
      }
      &.licence-price {
        width: 35%;
      }
      &.licence-price-off {
        width: 35%;
        span {
          color: @majeure;
        }
      }
      &.licence-fixed-quantity,
      &.licence-quantity,
      &.licence-selection {
        width: 20%;
        text-align: right;
        .sp-quantity {
          float: right;
          > * {
            float: left;
            color: @grismoyen;
            background-color: #fff;
            border: 1px solid #ccc;
            padding: 0 5px;
            height: 20px;
            display: block;
            padding-top: 3px;
          }
          > .sp-minus,
          .sp-plus {
            &.disabled {
              pointer-events: none;
              cursor: not-allowed;
              filter: alpha(opacity=65);
              -webkit-box-shadow: none;
              box-shadow: none;
              opacity: .65;
            }
            &:hover {
              background-color: @majeure;
              border-color: @majeure;
              color: white;
            }
          }
          > .sp-minus {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          > .sp-plus {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }

          > .sp-qty {
            border-width: 1px 0 1px 0;
            min-width: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}

div.sep-rouge {
  border-top: 3px dotted @majeure !important;
  margin-bottom: 10px;
}

div.total {
  border-top: none !important;
  font-family: 'Nexa-Bold', sans-serif;
  font-size: 18px;
  padding: 10px 0;
  line-height: 1;
  vertical-align: middle;
}

#payment {
  position: relative;
  ul {
    margin-bottom: 20px;
    li {
      margin-bottom: 10px;
      #card-number {
        display: inline-block;
        width: 240px;
      }

      .security-and-helper {
        #security-code {
          display: inline-block;
          width: 130px;
        }
        @media(min-width: 578px) {
          display: inline-block;

        }
        @media(max-width: 577px) {
          margin-top: 10px;
        }
      }

      #card-month,
      #card-year {
        display: inline-block;
        width: 100px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        @media(max-width: 577px) {
          display: block;
        }
      }

      #card-month {
        @media(max-width: 577px) {
          margin-bottom: 10px;
        }
      }

      .glyphicon.glyphicon-question-sign {
        top: 3px;
        color: #4c8ae7;
        cursor: pointer;
      }
      .glyphicon.glyphicon-warning-sign {
        color: @majeure !important;
        font-size: 30px;
        margin-right: 10px;
      }
      i.find-security {
        background-image: url(public/images/cards.png);
        background-repeat: no-repeat;
        display: block;
        height: 100px;
        width: 190px;
        background-position: 0 -51px;
      }
      &.error input,
      &.error select {
        border-color: @rouge;
      }
    }
  }
}

.card-type {
  margin-right: 5px;
  button {
    width: 70px;
    &.current {
      box-shadow: inset 0 0 4px #51a7e0;
      background-color: #acdfaf;
      border-color: #adadad;
    }
    > i {
      background-image: url(public/images/cards2.png);
      background-repeat: no-repeat;
      display: block;
      height: 31px;
      width: 51px;
      &.cb {
        background-position: 0 0;
        width: 37px;
        height: 27px;
        margin-left: 13px;
      }
      &.mastercard {
        background-position: -37px 1px;
        width: 37px;
        height: 27px;
        margin-left: 12px;
      }
      &.visa {
        background-position: -74px 0;
        width: 46px;
        height: 27px;
        margin-left: 5px;
      }
    }
  }
  &.error button {
    border-color: @majeure;
  }
}

.helper .popover .popover-content {
  background-image: url(public/images/cards.png);
  background-repeat: no-repeat;
  display: block;
  height: 100px;
  width: 185px;
  background-position: 0 -51px;
  margin: 10px 0 5px 30px;
}

.licenceNumber {
  .input-group {
    width: 170px;
    input {
      font-size: 12px;
    }
  }
  .input-group-btn .btn {
    padding: 7px 4px;
  }
}

a .select2-chosen {
  font-family: 'Nexa-Light', sans-serif;
}

.licence-list {
  a.upgrade,
  a.delete {
    font-size: 12px;
    .glyphicon {
      color: @majeure !important;
    }
  }
}

#account-nav > li.brand {
  text-transform: none;
  font-size: 18px;
}

.reassurance {
  background: url('/public/images/reassurance.png');
  width: 200px;
  height: 67px;
  position: absolute;
  right: 20px;
  top: 20px;
  @media(max-width: 700px) {
    display:none;
  }
}

div.account-legal {
  font-size: 11px;
  margin-top: 10px;
}
.btn.btn-xs.btn-danger {
  min-width: 120px;
  text-align: center;
}
.btn.btn-xs.btn-danger.btn-check {
  min-width: auto;
  padding-top:2px;
}

.input-group-addon + .form-control {
  color: @majeure;
  border-radius: 0 4px 4px 0 !important;
  background-color: white!important;
  &:focus {
    box-shadow: none;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  &#login-username,
  &#login-password {
    background-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
}
.basket-page {
  margin-top: 30px;
  margin-bottom: 30px;
}

.alert {
  margin-top: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.controls {
  text-align: center;
  .btn-danger {
    border: none;
   // transition: background-color 160ms @transition-easing;
    &:hover {
      background-color: @mineure;
    }
  }
}
.explanation-licence {
  font-size: 13px;
  margin-top: 20px;
  .explanation-licence-title {
    color: @corail;
  }
}
#account-content .btn.btn-danger {
  padding: 4px 15px;
  font-size: 14px;
  border-color: @majeure;
  transition: background-color 160ms @transition-easing;
  &:hover {
    background-color: @mineure;
    border-color: @mineure;
  }
}
.span-same-line {
  display: inline-block;
  margin-top: 0;
  background-color: transparent;
}
a.forgot-pwd__link{
  color: white;
  &:hover {
    color: @mineure;
  }
}
.password-form .input-group {
  width: 100%;
}
.password__field {
  width: 100%;
}
#account-content .panel-info .panel-body a.licenses__link-colored,
#account-content .panel-info #eula-collapse a.licenses__link-colored{
  color: @mineure;
  &:hover {
    text-decoration: underline;
  }
}