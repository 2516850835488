/************************** FOOTER *********************/
footer {
  position: relative;
  background-color: @majeure;
  > div {
    padding-left: 10px;
    padding-right: 10px;
    @media(min-width: 1300px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.fo-content-inner {
  .contact-elem {
    display: flex;
    flex-direction: column;
    @media(min-width: 992px) {
      flex-direction: row;
      align-items: flex-end;
      > div {
        height: 50px;
      }
    }
  }
  .tel-btn span {
    padding-left: 10px;
  }
  .tel-btn, .mail-btn {
    margin: 10px 0;
    @media(min-width: 992px) {
      margin: 0;
      padding: 0 20px;
      border-right: 1px solid white;
    }
  }
  .contact-btn {
    display: block;
    padding: 5px 10px;
    color: white;
    background-color: @majeure;
    @media(min-width: 992px) {
      padding: 5px 10px 20px 10px;
    }
  }
}
.fo-content-inner--support {
  justify-content: flex-end;
}
.support__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: @grisbleufonce;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    background-color: @mineure;
  }
  .support__icon {
    width: 40px;
    height: 40px;
    fill: white;
  }
}
.fo-content-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  @media(min-width: 768px) {
    padding-top: 70px;
  }
}
.fo-content-inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
  @media(min-width: 992px) {
    flex-direction: row;
  }
}
.col-fo {
  margin: 10px auto 0 auto;
  padding-bottom: 10px;
  flex: 0 1 calc(~"100% / 3");
  order: 1;
  @media(min-width: 768px) {
    margin: 25px auto 0 auto;
    padding-bottom: 25px;
    order: 0;
  }
}
.col-fo--newsletter {
  order: 0;
  padding-bottom: 25px;
  @media(min-width: 768px) {
    padding-bottom: 35px;
    order: 1;
  }
}
.col-fo .col-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media(min-width: 992px) {
    justify-content: center;
  }
}
.fo-content-inner--bottom .col-fo {
  display: flex;
  align-items: center;
}
.col-fo--copyright {
  margin: 0 auto;
  justify-content: center;
  @media(min-width: 768px) {
    margin: 25px auto 0 auto;
  }
}
.col-fo-inner {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 20px;
  }
  @media(min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media(min-width: 1300px) {
    > div {
      margin: 0 85px;
    }
  }
}
.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px) {
    align-items: flex-end;
  }

}
.footer-title {
  padding-bottom: 30px;
  .chaney-reg;
  font-size: 20px;
  text-transform: uppercase;
  color: @grisclair;
  text-align: center;
  @media(min-width: 768px) {
    padding-bottom: 45px;
    font-size: 32px;
    text-align: inherit;
  }
}
.footer-title:empty {
display: none;
}

.container-paiement .footer-title {
display: flex;
flex-direction: row;
align-items: center;
&:after {
  margin-left: 15px;
  width: 57px;
  height: 26px;
  content: '';
  background-image: url('/public/images/pictos/cadenas-secure.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
}
@squareli-height: 5px;
@square-size: 5px;
.liens-footer {
.footer-title {
  padding-bottom: 15px;
}
ul {
  text-align: center;
  list-style-type: none;
}
li {
  .heavyfont;
  .fs-18;
  line-height: 2.1;
  text-transform: uppercase;
  color: @grisbleufonce;
  a {
    position: relative;
    display: inline-block;
    padding-bottom: 0;
    .heavyfont;
    text-transform: uppercase;
    color: @grisbleufonce;
    .fs-18;
  }
}
li a:hover {
  color: @grisbleu;
}
}
.col-partenaires {
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
img {
  margin: 0 40px;
}
}

/******************* NEWSLETTER *****************/
.form-group {
  display: flex;
  margin-bottom: 0;
}
.form-control {
  padding: 15px 18px 10px 15px;
  width: 230px;
  height: 48px;
  .bookfont;
  font-size: 14px;
  border: 0 solid @grisbleufonce;
  border-radius: 14px;
  background-color: @grisbleufonce;
  color: white;
  @media(min-width: 768px) {
    width: 250px;
  }
}

.btn-keep .btn.btn-danger {
  margin-left: 8px;
  width: 64px;
  height: 48px;
  .heavyfont;
  font-size: 16px;
  text-transform: uppercase;
  background-color: @mineure;
  border-radius: 14px;
  transition: color 160ms @transition-easing, background-color 160ms @transition-easing;
  &:hover {
    color: @majeure;
    background-color: white;
  }
}
.liensbottomfooter {
  margin-left: auto;
  width: 100%;
  max-width: 340px;
  ul {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    list-style-type: none;
    @media(min-width: @screen-md) {
      justify-content: flex-end;
    }
    li {
      position: relative;
      &:after {
        margin: 0 15px;
        content: "|";
        color: white;
      }
      &:last-of-type:after {
        content: none;
      }
    }
    li a {
      .basefont;
      font-size: 12px;
      text-transform: uppercase;
      color: white;
      @media(min-width: 768px) {
        font-size: 14px;
      }
      &:hover {
        color: @mineure;
      }
    }
  }
}
.logo-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    width: 90%;
  }
}
.copyright {
  padding-top: 15px;
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  @media(min-width: 768px) {
    font-size: 14px;
  }
}
.reseau-social li a img {
  height: 20px!important;
  width: auto;
  &.youtube {
    height: 24px!important;
  }
}
.edition__link {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  a {
    display: block;
    width: 16px;
    height: 16px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
.newsletter-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media(min-width: 500px) {
    flex-direction: row;
    align-items: inherit;
  }
  .btn-keep {
    margin-top: 10px;
    @media(min-width: 500px) {
      margin-top: 0;
    }
  }
}

#mail::placeholder {
  color: white;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  &#mail {
    -webkit-text-fill-color: white;
    color: white!important;
    background-color: @majeure;
    transition: background-color 5000s ease-in-out 0s;
  }
}
