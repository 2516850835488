//******************************GALLERY******************************************/
.image div { margin: 15px 0; }
.gallery-outer {
  max-width: 1540px;

}
.contenu-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 1095px) {
    justify-content: flex-start;
  }
  @media(min-width: 1540px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.image-centree {
  .bloc-gallery-outer {
    img {
      width: auto;
    }
  }
}


section[data-class="largeimagegallery"].gallery,
section[data-class="largeimagegalleryext"].gallery,
section[data-class="modulevideo3"].bloc-video,
section[data-class="mediumimagegallery"].gallery,
section[data-class="mediumimagegalleryext"].gallery,
section[data-class="modulevideo2"].bloc-video,
section[data-class="smallimagegallery"].gallery,
section[data-class="smallimagegalleryext"].gallery,
section[data-class="modulevideo1"].bloc-video {
  @media(min-width: 1540px) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

section[data-class="smallimagegallery"],
section[data-class="smallimagegalleryext"],
section[data-class="modulevideo1"] {
  .bloc-gallery-outer {
    width: 100%;
    max-width: 500px;
  }
  .grouped_elements, .url-externe {
    display: block!important;
  }
}
section[data-class="mediumimagegallery"],
section[data-class="mediumimagegalleryext"],
section[data-class="modulevideo2"] {
  .bloc-gallery-outer {
    width: 100%;
    max-width: 760px;
  }
  .grouped_elements, .url-externe {
    display: block!important;
  }
}
section[data-class="largeimagegallery"],
section[data-class="largeimagegallery"],
section[data-class="modulevideo3"] {
  .bloc-gallery-outer {
    width: 100%;
    max-width: 1540px;
  }
  .grouped_elements, .url-externe {
    display: block!important;
  }
}

.legendevig {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: @gristitle;
  .gallery-picto {
    width: 100%;
    max-width: 75px;
    max-height: 75px;
  }
  .titre-vig {
    padding-top: 15px;
    .fs-20;
    text-transform: uppercase;
    color: white;
    &:empty {
      display: none;
    }
  }
  .soustitre-vig {
    padding-bottom: 12px;
    .fs-14;
    color: @majeure;
    &:empty {
      display: none;
    }
  }
}
@media(max-width: 380px) {
  .legendevig {
    .titre-vig {
      .fs-14;
    }
    .soustitre-vig {
      .fs-12;
    }
  }
}
@media(max-width: 300px) {
  .legendevig {
    .titre-vig {
      .fs-12;
    }

  }
}
.cms--editing .legendevig .titre-vig:empty,
.cms--editing .legendevig .soustitre-vig:empty {
  display: block;
}

.legendevig .entete-gallery-txt {
  .boldfont;
  text-align: center;
  width: 100%;
  padding-left: 0;
}
.fancybox-type-inline .fancybox-inner {
  height: auto!important;
  text-align: center;
}
.grouped_elements img, .url-externe img {
  max-width: 100%!important;
}

section[data-class="inlinevideo1"],
section[data-class="inlinevideo2"],
section[data-class="inlinevideo3"],
section[data-class="inlineplaylist1"],
section[data-class="inlineplaylist2"],
section[data-class="inlineplaylist3"] {
  div.bloc-gallery-outer {
    position: relative;
    overflow: hidden;
    margin:auto;
  }
}
section[data-class="inlinevideo1"],
section[data-class="inlineplaylist1"] {
  div.bloc-gallery-outer {
    max-width: 500px;
  }
}
section[data-class="inlinevideo2"],
section[data-class="inlineplaylist2"] {
  div.bloc-gallery-outer {
    max-width: 760px;
  }
}
section[data-class="inlinevideo3"],
section[data-class="inlineplaylist3"] {
  div.bloc-gallery-outer {
    max-width: 1100px;
  }
}

