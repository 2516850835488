/*********** Key Features *************/
.key-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 0;
  background-image: url(/public/images/shapes/bgd-curves.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: @grisclair;
}
.key-feature__zone {
  display: flex;
  margin: 0 -12.5px;
  padding: 0 7px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  max-width: @body-width;
  @media(min-width: 1300px) {
    padding: 0;
  }
  section:not(.feature){
    width: 100%;
  }
}
.feature {
  display: flex;
  margin: 5px;
  width: 100%;
  max-width: 295px;
  @media(min-width: 400px) {
    width: calc(~"50% - 10px");
  }
  @media(min-width: 768px) {
    margin: 25px 12.5px 0 12.5px;
    width: calc(~"50% - 25px");
  }
  @media(min-width: 820px) {
    margin: 25px 12.5px 0 12.5px;
    width: calc(~"25% - 25px");
  }
}
.key-feature__zone .button-editable {
  text-align: center;
}
div.bloc-logo-img {
  margin: 0 auto;
  width: 60px;
  text-align: center;
  padding: 20px 0;
  img {
    margin: 0 auto;
    height: 60px;
  }
}
.feature .bloc-gallery-outer {
  position: relative;
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  background-color: white;
  transition: background-color 160ms @transition-easing;
  box-shadow: 0 5px 20px rgba(0,0,0,.2);
  border-radius:10px;
  cursor: pointer;
  .feature-desc {
    padding: 0 10px 25px 10px;
    .feature-titre {
      text-align: center;
      .chaney-reg;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.2;
      color: @majeure;
      transition: color 160ms @transition-easing;
      @media(min-width: 768px) {
        font-size: 21px;
      }
      @media(min-width: 820px) {
        font-size: 16px;
      }
      @media(min-width: 992px) {
        font-size: 18px;
      }
      @media(min-width: 1200px) {
        font-size: 21px;
      }
    }
    .feature-detail {
      margin-bottom: 0;
      padding-top: 30px;
      .bookfont;
      font-size: 14px;
      text-align: center;
      color: @majeure;
      transition: color 160ms @transition-easing;
      @media(min-width: 768px) {
        padding: 30px 30px 0 30px;
        font-size: 16px;
      }
    }
  }
}
.feature .bloc-gallery-outer > * {
  pointer-events: none;
  touch-action: none;
}
.symbol__plus {
  position: absolute;
  bottom: 5px;
  right: 10px;
  margin-top: 10px;
  .boldfont;
  font-size: 24px;
  line-height: 0.7;
  text-align: right;
  color: @grisbleu;
}
.feature a.bloc-gallery-outer:hover {
  background-color: @grisfonce;
  box-shadow: -15px 20px 25px rgba(0,0,0,.3);
  .feature-titre,
  .feature-detail {
    color: white;
  }
  .symbol__plus {
    color: #ffffff;
  }
}

// Bloc AT THE HEART
.txt-logos__container {
  position: relative;
  margin-top: 130px;
  &:before {
    @media(min-width: 992px){
      position: absolute;
      width: 200px;
      height: 200px;
      top: -30px;
      left: 0;
      border-radius: 100%;
      background-color: @grisbleu;
      content: '';
      z-index: 0;
    }
    @media(min-width: 1320px) {
      width: 150px;
      height: 150px;
      top: -60px;
      left: -10px;
    }
    @media(min-width: 1380px) {
      width: 180px;
      height: 180px;
      left: -40px;
    }
    @media(min-width: 1610px) {
      width: 320px;
      height: 320px;
      top: -160px;
      left: -160px;
    }
  }
  &:after {
    @media(min-width: 992px){
      position: absolute;
      width: 170px;
      height: 170px;
      bottom: -50px;
      right: 0;
      background-image: url(/public/images/shapes/triangle-vert.png);
      background-size: 100%;
      background-repeat: no-repeat;
      content: '';
      z-index: 0;
    }
    @media(min-width: 1320px) {
      bottom: -42px;
      right: -10px;
    }
    @media(min-width: 1380px) {
      width: 200px;
      height: 200px;
      right: -40px;
    }
    @media(min-width: 1610px) {
      width: 321px;
      height: 321px;
      bottom: -42px;
      right: -160px;
    }
  }
}
.txt-logos__outer {
  position: relative;
  padding: 45px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: @majeure;
  border-radius: 20px;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 45px;
  }
  @media (min-width: 992px) {
    border-radius: 90px;
  }
}
.txt-logos__title {
  .chaney-reg;
  font-size: 28px;
  text-transform: uppercase;
  color: white;
  @media (min-width: 992px) {
    font-size: 48px;
  }
}
.txt-logos__subtitle {
  margin-top: 20px;
  .bookfont;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  @media(min-width: 500px){
    font-size: 25px;
  }
}
.txt-logos__text {
  margin-top: 50px;
  font-size: 18px;
  color: white;
}
.logos__container {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 960px;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
}
.logo-only {
  margin: 10px 0;
  width: 33%;
  max-width: 185px;
  @media (min-width: 768px) {
    margin: 0;
    width: 20%;
    order: 0;
  }
  &--2 {
    margin: auto;
    //padding-top: 40px;
    padding-bottom: 10px;
    height: 125px;
    @media (min-width: 500px) {
      margin-bottom: -20px;
      height: 140px;
      max-width: 125px;
    }
    @media (min-width: 600px) {
      padding-top: 20px;
      max-width: 135px;
      height: 170px;
    }
    @media (min-width: 758px) {
      max-width: 145px;
    }
    @media (min-width: 768px) {
      margin-bottom: 0;
      padding-top: 0;
      height: 80px;
      max-width: 110px;
    }
    @media (min-width: 992px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    img {
      padding-top: 60px;
      @media(min-width: 500px){
        padding-top: 50px;

      }
      @media(min-width: 600px){
        padding-top: 50px;

      }
      @media(min-width: 768px){
        padding-top: 0;
      }
    }
  }
  &--4,
  &--5 {
    margin-top: -20px;
    order: 1;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  &--5 {
    margin-left: -10px;
    order: 0;
    @media (min-width: 500px) {
      margin-left: -20px;
    }
    @media (min-width: 768px) {
      order: 1;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cms--editing .feature .bloc-gallery-outer > * {
  pointer-events: auto;
  touch-action: auto;
}


// SURCHARGE POUR AUTRE PAGES
.feature--akeytsu {
  margin: 5px 5px;
  max-width: none;
  width: calc(~"50% - 10px");
  @media(min-width: 600px){
    margin: 25px 12.5px;
    width: calc(~"50% - 30px");
  }
  @media(min-width: 820px) {
    margin: 25px 12.5px 0 12.5px;
    width: calc(~"25% - 25px");
  }
}
div.bloc-logo-img.bloc-logo-img--akeytsu {
  width: 100px;
  img {
    height: 100px;
  }
}
