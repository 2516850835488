.bloc-contenu__txt-img {
  position: relative;
  padding: 0 0 40px 0;
  @media (min-width: 768px) {
    padding: 0;
  }
  &:before {
    @media(min-width: 992px) {
      position: absolute;
      display: block;
      top: 0;
      width: 50%;
      height: 100%;
      background-color: #d3d3da;
      content: '';
    }
  }
}

.bloc-contenu__txt-img .contenu2col-outer {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 768px) {
    align-items: center;
    text-align: left;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    margin: 0 auto 0 0;
    justify-content: flex-start;
    background-size: auto 640px;
    background-repeat: no-repeat;
    background-position: left top;
  }
  @media (min-width: 1280px) {
    background-size: 920px 640px;}
  @media (min-width: 1800px) {
    margin: 0 auto 0 calc(~"(100% - 1600px) / 2");
  }
  .coltxt {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
    max-width: 920px;
    order: 1;
    @media (min-width: 768px) {
      padding-right: 10px;
      padding-left: 35px;
      width: calc(~"43% - 6px");
      margin-bottom: 20px;
      align-items: flex-end;
      justify-content: center;
    }
    @media (min-width: 992px) {
      justify-content: center;
      margin-bottom: 0;
      text-align: right;
    }
      > .coloured-dots {
        margin-left: -16px;
      }
    }
  .colimg {
    width: 100%;
    max-width: 920px;
    order: 0;
    @media (min-width: 768px) {
      width: 57%;
    }
    @media (min-width: 992px) {
      img {
        width: auto;
      }
    }
  }
  .texts-sliding__inner {
    text-align: justify;
    @media (min-width: 768px) {
      text-align: right;
    }
  }
  .coltxt__title span:after {
    @media(min-width: 768px) {
      left: auto;
      right: 0;
    }
  }
}
.coltxt__subtitle {
  margin-top: 15px;
  .boldfont;
  font-size: 22px;
}
.coltxt_desc {
  position: absolute;
  top: 25px;
  bottom: 20px;
  width: 100%;
  // max-width: 490px;
  .basefont;
  font-size: 18px;
  line-height: 1.5;
  color: @grismoyen;
  @media(min-width: 600px) {
    top: 45px;
    bottom: 30px;
  }
  a {
    display: block;
    padding: 8.5px 26px;
    .boldfont;
    font-size: 20px;
    color: white;
    float: right;
    background-color: @majeure;
  }
}
.bloc-contenu__even-odd .coltxt_desc {
  position: relative;
  @media(min-width: 768px){
    position: absolute;
    top: 25px;
    bottom: 20px;
  }
}
.bloc-contenu__txt-img:nth-of-type(odd) .contenu2col-outer {
  @media (min-width: 768px) {
    margin: 0 0 0 auto;
    justify-content: flex-end;
    background-position: right top;
    @media (min-width: 1800px) {
    margin: 0 calc(~"(100% - 1600px) / 2") 0 auto;
    }
    .coltxt {
      margin-right: 0;
      margin-left: 0;
      padding-left: 10px;
      padding-right: 35px;
      text-align: left;
      order: 0;
      @media (min-width: 768px) {
        align-items: flex-start;
      }
    }
    .texts-sliding__inner {
      @media (min-width: 768px) {
        text-align: left;
      }
    }
    .coltxt__title span:after {
      @media(min-width: 768px) {
        left: 0;
      }
    }
    .colimg {
      //margin-left: 35px;
      order: 1;
    }
  }
}
.bloc-contenu__txt-img:nth-of-type(even):before {
  left: 0;
}
.bloc-contenu__txt-img:nth-of-type(odd):before {
  right: 0;
}
.contenu2col-inner {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  @media(min-width: 768px){
    flex-direction: row;
  }
}
.texts-sliding  {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.texts-sliding__inner {
  //position: absolute;
  display: flex;
  width: 100%;
  min-height: 310px;
  transition: transform 320ms @transition-easing;
  @media(min-width: 440px) {
    min-height: 250px;
  }
  @media(min-width: 500px) {
    min-height: 200px;
  }
  @media(min-width: 768px) {
    min-height: 340px;
  }
  @media(min-width: 992px) {
    min-height: 290px;
  }
  @media(min-width: 1200px) {
    min-height: 240px;
  }
}
.bloc-contenu__txt-img:nth-of-type(1) .contenu2col-outer {
  @media (min-width: 992px) {
    background-image: url(/public/images/backgrounds/bgd-01.jpg);
  }
  .coloured-dot--active {
    background-color: @mineure;
  }
}
.bloc-contenu__txt-img:nth-of-type(2) .contenu2col-outer {
  @media (min-width: 992px) {
    background-image: url(/public/images/backgrounds/bgd-02.jpg);
  }
  .coloured-dot--active {
    background-color: @green;
  }
}
.bloc-contenu__txt-img:nth-of-type(3) .contenu2col-outer {
  @media (min-width: 992px) {
    background-image: url(/public/images/backgrounds/bgd-03.jpg);
  }
  .coloured-dot--active {
    background-color: @blue;
  }
}
.bloc-contenu__txt-img.bloc-contenu__even-odd {
  width: 100%;
  .contenu2col-outer {
    background: none;
  }
  &:before {
    content: none;
  }
}
.bloc-contenu__txt-img.bloc-contenu__even-odd .texts-sliding {
  overflow: visible;
  margin-bottom: 20px;
  @media(min-width: 768px) {
    margin-bottom: 0;
  }
  .texts-sliding__inner {
    min-height: 220px;
    @media(min-width: 440px) {
      min-height: 250px;
    }
    @media(min-width: 500px) {
      min-height: 200px;
    }
    @media(min-width: 768px) {
      min-height: 340px;
    }
    @media(min-width: 992px) {
      min-height: 290px;
    }
    @media(min-width: 1200px) {
      min-height: 240px;
    }
  }

}

.coltxt__title {
  .chaney-reg;
  font-size: 26px;
  color: @majeure;
  @media(min-width: 500px){
    font-size: 30px;
    white-space: nowrap;
  }
  @media (min-width: 1100px) {
    font-size: 38px;
  }
  @media (min-width: @body-width) {
    font-size: 42px;
  }
  @media (min-width: 1340px) {
    font-size: 48px;
  }
}
.coloured-dots {
  display: flex;
  list-style-type: none;
  height: 32px;

}
body:not(.home) .coloured-dots {
  margin-top: -20px;
  margin-bottom: 40px;
  align-self: flex-end;
  width: 100%;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 1.1;
  color: @majeure;
  @media (min-width: 768px) {
    margin-top: 0;
    align-self: inherit;
    font-size: 36px;
    justify-content: center;
    letter-spacing: 16px;
  }
  @media (min-width: 992px) {
    //text-align: inherit;
  }
}
.coloured-dot {
  display: block;
  margin: 0 16px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  cursor: pointer;
  background-color: @grisbleufonce;
}
.bloc-contenu__txt-img:nth-of-type(even) .coloured-dots {
  margin-right: -16px;
  @media (min-width: 768px) {
    align-self: flex-end;
  }
}
.link__editable {
  cursor: pointer;
/*  @media (min-width: 768px) {
    align-self: flex-start;
  }*/
  @media (min-width: 992px) {
    align-self: inherit;
  }
}
.link__text {
  .boldfont;
  font-size: 16px;
  text-transform: uppercase;
  @media (min-width: 992px) {
    font-size: 18px;
  }
}

.cms--editing .bloc-contenu__txt-img .contenu2col-outer .texts-sliding__inner {
  flex-direction: column;
  transform: none!important;
}
.cms--editing .bloc-contenu__txt-img .contenu2col-outer .coltxt_desc {
  position: relative;
  left: 0!important;
  margin-bottom: 30px;
}