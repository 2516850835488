
.navbar-wrapper .navbar-toggle:hover,
.navbar-wrapper .navbar-toggle:focus {
  .icon-bar {
    background-color: @mineure;
  }
}

.navbar-wrapper .navbar-toggle .icon-bar {
  margin-top: 6px;
  width: 24px;
  background-color: white;
}

.navbar-header,
.navbar-nav > li {
  float: none;
}

.navbar-collapse.collapse {
  @media (min-width: 992px),
  not (hover: none) and (pointer: coarse){
    display: flex !important;
  }
}

html:not(.tablet) .mainnav {
  width: 100%;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: @hauteurheader;
  }
  ul.navbar-nav {
    @media (min-width: 992px),
    not (hover: none) and (pointer: coarse){
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      height: @hauteurheader;
      cursor: default;
    }
  }
  .nav1 {
    background-color: @gristitle;
    //background-color: #3a3a4d;
    @media (min-width: 992px) {
      background-color: transparent;
    }
  }
  .nav1-li {
    list-style-type: none;
    -webkit-backface-visibility: hidden;
    cursor: default;
    @media (min-width: 992px),
    not (hover: none) and (pointer: coarse){
      display: flex;
      flex: 1 1 24%;
      white-space: nowrap;
    }
    @media (min-width: 110px) {
      flex: 1 1 25%;
      white-space: nowrap;
    }
    &.account {
      flex: 0 0 auto;
      justify-content: center;
      span {
        @media (min-width: 992px) {
          display: none;
        }
      }
    }
  }
  .nav1-li-a {
    position: relative;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    height: auto;
    min-height: 30px;
    padding-left: 16px;
    //justify-content: center;
    align-items: center;
    .boldfont;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: white;
    border-bottom: 0.5px solid @majeure;
    background-color: @mineure;
    @media (min-width: 992px),
    not (hover: none) and (pointer: coarse){
      padding-left: 0;
      border-bottom: none;
      min-height: @hauteurheader;
      color: white;
      background-color: transparent;
      transition: color 320ms @transition-easing;
    }
    @media (min-width: 1200px) {
      font-size: 18px;
    }
    &:hover, &:focus, &.underline,
    &.current, &.current:hover {
      color: white;
      background-color: @mineure;
      .dropdown-arrow {
        border-top: 6px solid white;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
      @media (min-width: 992px),
      not (hover: none) and (pointer: coarse){
        color: @mineure;
        background-color: transparent;
        .dropdown-arrow {
          border-top: none;
          border-bottom: 6px solid @mineure;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }
      }
    }
  }

  .get-license {
    @media (min-width: 992px),
    not (hover: none) and (pointer: coarse){
      width: auto;
    }
    .nav1-li-a {
      text-transform: none;
      @media (min-width: 992px),
      not (hover: none) and (pointer: coarse){
        display: flex;
        padding: 6px 15px 0 15px;
        min-height: 40px;
        .heavyfont;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 0;
        color: white;
        background-color: @mineure;
        border-radius: 14px;
        transition: color 320ms, background-color 320ms @transition-easing;
        &:hover,
        &.current {
          color: @mineure;
          background-color: white;
        }
      }
      span {
        margin-left: 6px;
      }
    }
  }
}

.mainnav .navbar-nav.nav1 {
  @media (min-width: @body-width),
  not (hover: none) and (pointer: coarse){
    margin-right: 0;
    margin-left: 210px;
    @media(min-width: 1200px) {
      max-width: 610px;
    }
  }
}

.mainnav .navbar-nav.nav1--account {
  @media (min-width: 992px),
  not (hover: none) and (pointer: coarse){
    margin-left: 0;
  }
  @media(min-width: 1200px) {
    max-width: none;
  }
}

.nav1.navbar-nav .account {
  @media (min-width: 992px),
  not (hover: none) and (pointer: coarse){
    margin-left: 30px;
    width: auto;
  }
  @media (min-width: 1200px) {
    margin-left: 28px;
  }
  > a {
    @media (min-width: 992px),
    not (hover: none) and (pointer: coarse){
      position: relative;
      padding-right: 0;
      .boldfont;
      font-size: 16px;
    }
    .icon-container {
      display: none;
      @media (min-width: 992px),
      not (hover: none) and (pointer: coarse){
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 32px;
        height: 32px;
        .icon-account {
          height: 100%;
          fill: white;
        }
      }
    }
    &:hover {
/*      color: @navcolor;
      background-color: white;*/
      .icon-account {
        fill: @mineure;
      }
    }
  }
}

.nav1.navbar-nav .logout {
  @media (min-width: 992px),
  not (hover: none) and (pointer: coarse){
    margin-left: 0;
  }
  @media (min-width: 1100px) {
    margin-left: 15px;
  }
  > a {
    @media (min-width: 992px),
    not (hover: none) and (pointer: coarse){
      padding: 0 6px;
    }
    .icon-container {
      @media (min-width: 992px),
      not (hover: none) and (pointer: coarse){
        margin: 0;
      }
      .icon-logout {
        height: 100%;
        fill: white;
      }
    }
    &:hover {
      .icon-logout {
        fill: @mineure;
      }
    }
  }
}

.dropdown-arrow {
  display: inline-block;
  margin-left: 16px;
  margin-bottom: 4px;
  width: 0;
  border-top: 6px solid white;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  @media (min-width: 992px) {
    border-top: 6px solid white;
    transition: border 320ms @transition-easing;
  }
}

.fixed {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 20;
}

/************** SURCHARGE BOOTSTRAP *****************/

@media (max-width: 991px),
  /* Pas d'event hover */
 (hover: none) and (pointer: coarse){
  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    max-width: 300px;
    border-top: none;
    box-shadow: none;
    //max-height: 295px;
    max-height: calc(~"100vh - 80px");
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    margin-top: 7.5px;
    background-color: @gristresclair;
  }

  .navbar-nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
  }

  .collapse.in {
    display: block !important;
    //box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.3);
    min-width: 150px;
  }
}

.navbar-wrapper .navbar-collapse {
  display: flex;
  flex-direction: column;
  width: calc(~"100% - 145px");
  justify-content: space-between;
  border-color: transparent;
  @media (min-width: 500px) {
    width: calc(~"100% - 160px");
  }
  @media (min-width: 992px) {
    flex-direction: row;
    width: calc(~"100% - 200px");
  }
  @media (min-width: 1100px) {
    flex-direction: row;
    width: calc(~"100% - 160px");
  }
}

.navbar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  width: 100%;
  height: @hauteurheader;
  @media (min-width: 992px) {
    padding: 0;
    width: auto;
  }
}

.mainnav {
  &.navbar-wrapper {
    background-color: initial;
    border: initial;
  }
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: auto;
  }
  &.navbar {
    border-radius: initial;
    margin-bottom: 0;
  }
  .navbar-nav {
    margin: 0;
    > li > a {
//      padding-left: 0;
      padding-right: 0;
    }
  }
  .nav > li > a {
    padding-top: 4px;
    padding-bottom: 0;
    @media (min-width: 992px) {
      padding-top: 0;
      padding-right: 42px;
    }
    @media (min-width: 1200px) {
      padding-top: 0;
      padding-right: 45px;
    }
  }
}


@media (max-width: 991px),
  /* Pas d'event hover */
  (hover: none) and (pointer: coarse){
  .navbar-toggle {
    display: block;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .collapse.in {
    display: flex !important;
  }
}

.navbar-collapse.in, .navbar-collapse.collapsing {
  //overflow-y: visible!important;
  overflow-y: auto !important;
  position: relative;
  margin-right: 0;
  margin-left: auto;
}

.navbar-collapse.in {
  box-shadow: -3px 3px 5px rgba(0, 0, 0, .3);
}

/*************** NAV SECONDAIRE **************/
@media (min-width: @screen-md),
not (hover: none) and (pointer: coarse){
  ul.cd-secondary-nav {
    padding-left: 0;
  }
}

.cd-secondary-nav {
  padding-left: 0;
  @media (min-width: @screen-md) {
    position: absolute;
    top: @hauteurheader;
    left: 0;
    max-height: 0;
    overflow: hidden;
    margin-left: -15px;
    width: auto;
    background-color: @navcolor;
  }
  @media (min-width: @screen-lg) {
    width: auto;
  }
  @media (min-width: 1300px) {
    margin-left: -25px;
    //width: 180px;
  }
  .nav2-li {
    display: block;
    width: 100%;
    height: 30px;
    background-color: @navcolor;
    border-bottom: 0.5px solid @grisclair;
    cursor: pointer;
    &:last-of-type {
      border-bottom: none;
    }
    @media (min-width: 991px),
    not (hover: none) and (pointer: coarse){
      display: flex;
      align-items: center;
      padding: 0 15px;
      width: 100%;
      height: 44px;
      border-bottom: none;
    }
    a {
      display: block;
      margin: 0;
      padding-left: 20px;
      padding-right: 3px;
      text-align: left;
      .fs-12;
      text-transform: uppercase;
      color: white;
      line-height: 2.25;
      vertical-align: middle;
      @media (min-width: 992px),
      not (hover: none) and (pointer: coarse){
        padding-right: 5px;
        padding-left: 8px;
        width: 100%;
        height: 32px;
      }
      @media (min-width: 1300px) {
        padding-right: 10px;
        padding-left: 10px;
        font-size: 14px;
        letter-spacing: 1px;
      }
      &.current {
        color: white;
        @media (min-width: 992px) {
          color: @mineure;
        }
      }
    }
    &:last-of-type:after {
      border-bottom: none;
    }
    &:hover {
      a {
        color: white;
        background-color: @majeure;
        @media (min-width: 992px) {
          color: @mineure;
          border-bottom: none;
        }
      }
    }
  }
}

.nav1 .akeytsu .cd-secondary-nav {
  @media (min-width: 992px),
  not (hover: none) and (pointer: coarse){
    width: 170px;
  }
  @media (min-width: 1300px) {
    width: 230px;
  }
  li {
    width: auto;
  }
}

.show-nav {
  @media (min-width: @screen-md),
  not (hover: none) and (pointer: coarse){
    overflow: visible;
    transition: max-height 0.3s ease;
    &:hover {
      overflow: visible;
    }
  }
}

.navbar-nav .get-license .nav2-li {
  span:nth-of-type(2) {
    display: none;
  }
}