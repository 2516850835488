.contenu-lincense {
  display: flex;
  flex-wrap: wrap;
}
.navigation-tabs {
  padding: 30px 10px;
  display: flex;
  justify-content: center;
  @media(min-width: 992px) {
    margin: 40px 0 0 0;
    padding: 0 15px;
    border-bottom: 2px solid @corail;
  }
}
.navigation-license {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
  list-style-type: none;
  padding-inline-start: 0;
  flex-direction: column;
  @media(min-width: 768px) {
    flex-direction: row;
    //width: auto;
  }
}
.nav-license__li {
  margin: 5px 0;
  text-align: center;
  width: auto;
  @media(min-width: 768px) {
    margin: 0 5px;
    width: calc(100% / 4);
  }
  a {
    display: block;
    height: 100%;
    .boldfont;
    font-size: 20px;
    padding: 8px 5px 5px 5px;
    border-radius: 10px;
    color: @grismoyen;
    background-color: @gristresclair;
    @media(min-width: 768px) {
      border-radius: 10px 10px 0 0;
    }
    @media(min-width: 992px) {
      padding: 8px 15px 5px 15px;
      height: 100%;
    }
    &:hover,
    &.current {
      color: white;
      background-color: @majeure;
    }
    span{
      display: block;
      &:nth-of-type(2) {
        .basefont;
        font-size: 15px;
        font-weight: 100;
      }
    }
  }
}

.get-license .nav-license__li:first-of-type a {
  background-color: @majeure;
}
.bloc-buy {
  padding: 10px;
  width: 100%;
  border-bottom: 3px solid @gristresclair;
  .press-txt1 {
    .basefont;
    .fs-30;
    line-height: 38px;
    b {
      .boldfont;
    }
  }
}
.button-order__link {
  display: flex;
  padding: 10px 15px 6px 15px;
  justify-content: center;
  min-width: 160px;
  .bookfont;
  font-size: 17px;
  color: white;
  background-color: @majeure;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: @mineure;
  }
  &--grey{
    background-color: @majeure;
    &:hover {
      background-color: @mineure;
    }
  }
}
.button-order.three-buttons {
  .button-order__link {
    justify-content: flex-start;
    padding-left: 44px;
    min-width: 138px;
    &.button-order__windows, &.button-order__linux, &.button-order__osx {
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: 12px;
      margin-top: 5px;
    }
    &.button-order__windows {
      background-image: url('/public/images/pictos/windows.png');
    }
    &.button-order__osx {
      background-image: url('/public/images/pictos/osx.png');
    }
    &.button-order__linux {
      background-image: url('/public/images/pictos/linux.png');
    }
  }
}
.button-order.centered-button {
  margin: auto;
}
