a {
  transition: all 160ms @transition-easing;
}

@media print {
  a[href]:after {
    content: none;
  }
}

i:not(.glyphicon) {
  color: @majeure;
}

/********************** CONTENU PAGE **********************/
.contenu-page {
  margin: 0 auto;
  width: 100%;
}

.bloc-rond-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto 0 auto;
  width: 100%;
  max-width: @body-width;
  @media (min-width: @screen-md) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.bloc-rond-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
  @media (min-width: @screen-sm) and (max-width: @screen-md) {
    flex-direction: row;
    max-width: 100%;
  }
  @media (min-width: @screen-md) and (max-width: @screen-lg) {
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
  }
  .img-rond {
    overflow: hidden;
  }
}

.txt-rond-outer {
  margin-top: 30px;
  @media (min-width: @screen-sm) and (max-width: @screen-md) {
    margin-left: 40px;
    width: 50%;
  }
  .titre-bloc-rond {
    .boldfont;
    .fs-24;
    color: @gristitle;
    text-align: center;
    i {
      font-style: normal;
      color: @majeure;
    }
    @media (min-width: @screen-sm) and (max-width: @screen-md) {
      text-align: left;
    }
  }
  .accr-bloc-rond {
    margin-top: 20px;
    .boldfont;
    .fs-20;
    color: @gristitle;
    text-align: justify;
  }
  .txt-bloc-rond {
    margin-top: 15px;
    .basefont;
    .fs-16;
    color: @gristitle;
    text-align: justify;
  }
}

.txt-fullwidth {
  margin: 35px auto;
  padding: 0 10px;
  width: 100%;
  max-width: @body-width;
  @media (min-width: 992px) {
    margin: 65px auto;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
  .titresection {
    margin: 0 auto 15px auto;
    @media (min-width: 992px) {
      margin: 0 auto 35px auto;
    }
  }
  h3 {
    .fs-36;
    line-height: 2.75rem;
    @media (min-width: 992px) {
      .fs-50;
    }
  }
  .letexte {
    .boldfont;
    .fs-18;
    line-height: 30px;
    color: @grismoyen;
    @media (min-width: 992px) {
      margin: 1.25rem 0;
    }
  }
}

/*********/

b, strong {
  .boldfont;
}

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0.2em 0 0.1em 0;
  line-height: 2.25rem;
  @media (min-width: @screen-lg) {
    line-height: 1.3em;
  }
}

h1 {
  .basefont;
  font-weight: 200;
  margin: 0 0 0.3em 0;
  font-size: 2.5em;
  color: @majeure;
}

h2 {
  .boldfont;
  font-size: 1.6em;
  color: @majeure;
}

section[data-class="titre3"] {
  width: 100%;}

.title-h1 {
  margin: 0;
  .chaney-reg;
  .fs-50;
  line-height: 3.5rem;
  color: @grismoyen;
  i {
    font-style: normal;
    color: @majeure;
  }
}

h4 {
  .bookfont;
  font-size: 1em;
}

li {
  line-height: 1.3em;
}

section {
  div.letexte {
    .basefont;
  }
  &.intropage h2 {
    font-size: 1.2em;
  }
  img, video {
    max-width: 100%;
  }
  ul.slides li {
    background: none;
    padding: 0;
  }
}

h1, h2 {
  i {
    // .rouge;
    font-style: normal;
  }
}

section[data-class="textesimple"] {
  padding: 0 10px;
  width: 100%;
  @media(min-width: 1300px) {
    padding: 0;
  }
  .letexte {
    font-size: 16px;
    line-height: 1.5;
    color: @majeure;
    @media (min-width: 992px) {
      margin: 15px 0;
    }
  }
}

.page .letexte {
  margin: 0 auto;
  width: 100%;
  a {
    color: @mineure;
  }
}

section.intropage {
  margin-bottom: 0.5em;
}

section.txtimg {
  margin-bottom: 1em;
}

section figure.adroite300 {
  float: right;
  margin-left: 10px;
  max-width: 300px;
  overflow: hidden;
}

section figure.agauche300 {
  float: left;
  margin-right: 10px;
  max-width: 300px;
  overflow: hidden;
}

.letexte ul, .letextecol1 ul, .letextecol2 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.letexte li, .letextecol1 li, .letextecol2 li {
  margin: 0.3em 0;
  padding-left: 12px;
  &::before {
    content: '';
    display: block;
    float: left;
    margin-top: 5px;
    margin-left: -12px;
    width: 5px;
    height: 5px;
    background-color: @majeure;
  }
}

.warning {
  div.alerte {
    width: 80%;
    margin: 1em auto;
    padding: 10px 20px;
    color: @majeure;
    background-color: lightgray;
    a {
      color: @majeure;
      text-decoration: underline;
    }
    ul {
      list-style: auto;
    }
    li {
      background: none;
      list-style-type: square;
      padding-left: 0;
    }
  }
}

//******************************VIDEOS******************************************/
section.video {
  .videorub {
    text-align: center;
  }
  iframe {
    max-width: 100%;
    width: 930px;
    height: 525px;
    //.ombre;
  }
}

.video {
  margin: 0 auto;
}

.videobox {
  position: relative;
  text-align: center;
  transition: all 0.6s ease;
  &:hover {
    -webkit-filter: brightness(80%);
    -moz-filter: brightness(80%);
    -ms-filter: brightness(80%);
    -o-filter: brightness(80%);
    transition: all 0.6s ease;
  }
}

//******************************GALLERY******************************************/

section[data-class="videogallery"].gallery,
section[data-class="modulevideo2"].gallery {
  width: 31.9002%;
  margin-right: 20px;
  padding-bottom: 20px;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

section[data-class="modulevideo1"].gallery {
  width: 45.2167%;
  margin-right: 89px;
  &:nth-of-type(2n) {
    margin-right: 0;
  }
}

//******************************BLOCS CONTENU HOME******************************************/
.mavhome {
  margin-bottom: 5px;
  img {
    .ombre;
  }
  a .vignette:after {
    background-size: 100px auto;
  }
}

.bloc-text {
  width: 465px;
}

.mavdroite .bloc-text {
  padding-left: 20px;
}

.mavgauche .bloc-text {
  padding-right: 20px;
}

section[data-class="mavfocus"] {
  .bloc1 {
    margin-right: 14px;
  }
  .bloc2 {
    margin-right: 0 !important;
  }
  img {
    margin-right: 14px;
  }
}

//****************************** LOGOS ******************************************/
.bloclogo {
  float: left;
  margin-right: 25px;
  &:nth-of-type(5n) {
    margin-right: 0;
  }
}



//******************************BLOCS CONTENU******************************************/
.imggauche {
  background-color: @gristresclair;
}

.imgdroite {
  background-color: white;
}

.imgsysgauche {
  background-color: @grisclair;
}

section[data-class="txtimgpleinelargeur"] {
  h2 {
    margin: 8px 0 12px 0;
    text-align: center;
  }
  img {
    .ombre;
  }
}

.colimg, .coltxt {
  display: inline-block;
  vertical-align: top;
}

.bloc-ombre figure {
  .ombre;
}




.imgsysgauche .titresection {
  margin: 10px auto 25px auto;
  color: white;
  b {
    text-transform: none;
  }
}

.contenusysreq-outer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 992px) {
    flex-direction: row;
  }
  .colimg {
    margin: 0 90px 30px 55px;
    width: 100%;
    max-width: 130px;
    @media (min-width: 992px) {
      margin: 0 90px 50px 55px;
    }
  }
}

.coltxt-sys-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  @media (min-width: @screen-sm) {
    margin-top: 0;
  }
}

.linetxt-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @media (min-width: @screen-xs) {
    flex-wrap: initial;
    justify-content: flex-start;
  }
  .img-sys figure {
    width: 75px;
    height: 75px;
  }
  .title-sys {
    display: table;
    margin: 0 40px;
    width: 100%;
    max-width: 90px;
    .boldfont;
    .fs-40;
    text-align: left;
    color: @grismoyen;
  }
  .txt-desc-sys {
    margin-top: 15px;
    .boldfont;
    .fs-16;
    text-align: justify;
    color: @gristitle;
    @media (min-width: @screen-sm) {
      margin-top: 0;
    }
  }
}

/******************** SOCIAL *******************/
.social .greyline {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: 2px;
  background-color: #d4d4d4;
}

.social section[data-class="soustitre-section"] {
  margin-top: 30px;
  width: 100%;
}

.soustitresection {
  margin: 30px auto 20px auto;
  padding: 0 10px;
  .chaney-reg;
  .fs-18;
  color: @majeure;
  @media (min-width: 992px) {
    .fs-20;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
  b {
    color: inherit;
  }
}

.social .soustitresection {
  text-align: center;
}

.contenu-social > .cms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  max-width: @body-width;
}

.picto-social a {
  display: flex;
  flex-direction: row;
  margin: 15px 20px;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background-color: @gristitle;
  border-radius: 90px;
  &:hover {
    background-color: @majeure;
  }
}

/************************************************************************/
/****************************** NEWS ******************************************/
/************************************************************************/

/****************************** Home news ******************************************/
.block-news-outer {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
}

.block-news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: @screen-md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 1580px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.bloc-accroche {
  display: inline-block;
  vertical-align: top;
  &.mav-article, &.mav-article-small {
    margin: 0 auto 20px auto;
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: 780px) {
      margin: 0 10px 20px 10px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.mav-article {
    width: 100%;
    max-width: 760px;
  }
  &.mav-article-small {
    width: 100%;
    max-width: 500px;
  }
}

.bloc-accroche.image-article img {
  width: 100%;
  max-width: 760px;
  height: 100%;
  max-height: 365px;
  box-shadow: none !important;
}

.mav-article-small .bloc-accroche.image-article img {
  max-width: 500px;
  max-height: 265px;
}

#mav-articles h2 {
  margin: 0 0 10px 0;
  color: @gray-light;
}

.mav-article, .mav-article-small {
  &:nth-child(2n) {

  }
  a {
    display: flex;
    flex-direction: column;
    background-color: @gristitle;
    &:hover {
      -webkit-filter: brightness(80%);
      -moz-filter: brightness(80%);
      -ms-filter: brightness(80%);
      -o-filter: brightness(80%);
      transition: all 0.6s ease;
    }
    h3 {
      //margin: 23px 0 0 0;
      .fs-20;
      .boldfont;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      color: white;
    }
  }
}

.date-article {
  padding: 4px 4px 0 4px;
  .boldfont;
  .fs-14;
  line-height: 1;
  color: @majeure;
}

.bloc-detail {
  height: 75px;
  text-align: center;
}

.description-article {
  margin-top: 10px;
  .basefont;
}

/**************** BLOC JOBS & PRESS ***************/
.contenu-page.fondgris {
  padding-top: 15px;
  padding-bottom: 60px;
  max-width: none;
  background-color: @gristresclair;
}

.social .contenu-page.fondgris {
  display: none;
}

section[data-class="imagepressjob"] {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width / 2;
}

.contenu-jobs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;
  min-height: 50px;
  width: 100%;
  max-width: @body-width;
  @media (min-width: 992px) {
    flex-direction: row;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
}

.img-illus-gauche {
  width: 100%;
  max-width: 1020px;
}

.contenu-job-right {
  margin-top: 20px;
  @media (min-width: 992px) {
    margin-top: 0;
    margin-left: 20px;
    width: 100%;
    max-width: 600px;
  }
}

.txt-press {
  position: relative;
  margin-bottom: 20px;
  height: 100%;
  color: @grismoyen;
  @media (min-width: @screen-md) {
    margin-bottom: 0;
    height: 390px;
  }
}

.about-us .txt-press {
  height: initial;
}

.entete-press {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  .entete-press-picto {
    width: 100%;
    max-width: 57px;
    height: 57px;
  }
  .entete-press-txt {
    margin-left: 15px;
    .press-txt1 {
      .basefont;
      .fs-30;
      line-height: 30px;
      b {
        .boldfont;
      }
    }
    .press-txt2 {
      .fs-20;
      color: @majeure;
    }
  }
}

.titre-presskit {
  padding-bottom: 10px;
  .boldfont;
  .fs-16;
  color: @majeure;
  a {
    color: @majeure;
    &:hover {
      color: @mineure;
    }
  }
}

.txt-desc-press {
  padding-bottom: 20px;
  .basefont;
  .fs-16;
  line-height: 24px;
  color: @gristitle;
}

.soustxt-desc-press {
  .basefont;
  .fs-16;
  text-align: justify;
}

.listing {
  display: flex;
  flex-direction: row;
  .boldfont;
  .fs-16;
  .list1, .list2 {
    width: 50%;
    overflow-wrap: break-word;
  }
  .list2 {
    margin-left: 20px;
  }
}

.listing .list1,
.listing .list2,
.listing .list3 {
  ul {
    padding-left: 0;
    list-style-type: none;
    li {
      position: relative;
      padding-left: 10px;
      line-height: initial;
      &:before {
        position: absolute;
        top: 6px;
        margin-left: -12px;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: @gristitle;
      }
    }
  }
}


.input-cv {
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 500px;
  @media (min-width: 1570px) {
    padding-left: 0;
    padding-right: 0;
  }
  .form-group {
    display: flex;
    flex-direction: row;
  }
  .champ-upload {
    width: 100%;
    background-image: url(/public/images/pictos/dropdown-arrow.svg);
    background-repeat: no-repeat;
    label {
      margin-bottom: 0;
    }
  }
  .form-control {
    width: 100%;
    background-color: @gristresclair;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .inputfile {
    width: 40px;
    height: 40px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 0;
  }
  .inputfile + label {
    margin-left: 40px;
    padding-top: 10px;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    .basefont;
    .fs-16;
    color: @gristitle;
    background-color: white;
    display: inline-block;
    box-shadow: inset 0px 3px 5px 0 rgba(0, 0, 0, 0.3);
    @media (min-width: @screen-md) {
      padding-top: 5px;
      .fs-24;
    }
  }
  .btn-keep {
    z-index: 2;
    .btn.btn-danger {
      height: 40px;
      width: 60px;
      .fs-16!important;
      border-radius: 0;
      @media (min-width: @screen-md) {
        width: 180px;
        .fs-20!important;
      }
      &:hover {
        color: white;
        background-color: @majeure;
        span {
          color: white;
        }
      }
      span {
        display: none;
        @media (min-width: @screen-sm) {
          display: inline-block;
          color: white;
        }
      }
    }
  }
}

/************** SURCHARGE SUPPORT **************/
.faq .contenu-jobs-inner .contenu-support {
  .entete-press {
    margin-bottom: 10px;
  }
  .txt-desc-press {
    padding-bottom: 12px;
    line-height: 20px;
  }
  .titre-presskit {
    padding-bottom: 5px;
    line-height: 1.25rem;
  }
  .list1, .list2 {
    word-break: break-word;
  }
}

/***************** STORY AKEYTSU ****************/
.akeytsu-story {
  display: flex;
  flex-direction: column;
  margin: 0 auto 25px auto;
  width: 100%;
  max-width: @body-width;
  @media (min-width: @screen-lg) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.story-txt-outer {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &.story1 {
    .story-img-outer {
      align-items: center;
      justify-content: center;
      @media (min-width: @screen-sm) {
        justify-content: space-between;
      }
      @media (min-width: 1200px) {
        justify-content: flex-end;
      }
      .story-img {
        @media (min-width: @screen-sm) and (max-width: 1200px) {
          margin-right: 80px;
          margin-left: -70px;
        }
        @media (min-width: 1200px) {
          margin: 0 auto;
        }
      }
    }
  }
  &.story2 {
    .story-img-outer {
      align-items: center;
      justify-content: space-between;
      @media (min-width: @screen-sm) {
        max-width: 580px;
        justify-content: space-between;
      }
      @media (min-width: 1200px) {
        margin-left: 0;
        justify-content: initial;
      }
    }
    .soustitre-story-inner {
      order: 1;
      @media (min-width: @screen-lg) {
        order: 0;
      }
    }
  }
}

.story-img-outer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  @media (min-width: @screen-sm) {
    flex-direction: row;
    align-items: flex-end;
  }
  @media (min-width: @screen-lg) {
    justify-content: space-between;
    max-width: 560px;
  }
}

.petit-soustitre {
  .boldfont;
  .fs-18;
  color: @grismoyen;
}

.motclef {
  text-align: center;
  .boldfont;
  .fs-50;
  color: @grismoyen;
  b {
    color: @majeure;
  }
  i {
    font-style: normal;
    text-decoration: underline;
  }
  @media (min-width: @screen-lg) {
    margin-top: 115px;
  }
}

.soustitre-story-inner .motclef {
  margin-top: 0;
  text-align: initial;
}

.story-img-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  .legende-img {
    .boldfont;
    .fs-28;
    color: @gristitle;
  }
}

/************* ACCOUNT ***********/
.page-license {
  width: 100%;
  max-width: @body-width;
  display: block;
  @media (min-width: @screen-sm) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: initial;
  }
  #account-nav, #account-content {
    margin-bottom: 30px;
    float: none;
    @media (min-width: @screen-sm) {
      margin-bottom: 0;
    }
  }
}

ul.licence-list li:hover, ul.licence-list li.current {
  border-left: 3px solid @majeure !important;
}

/**************** LEGAL ***************/
.legal .titresection {
  margin: 55px auto 20px auto;
}

.legal .soustitresection {
  margin: 10px auto 20px auto;
  a:hover {
    color: @mineure;
  }
}

/********************* VERSION HISTORY ***********************/
.history__main-container {
  padding: 0 10px;
  margin: 50px auto 0 auto;
  width: 100%;
  max-width: @body-width;
  @media(min-width: 1300px) {
    padding: 0;
  }
}

/********************* PAGES EN EXTRA ****************/
.contenu.page-extra {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
}

.contenu-extra {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: center;
  @media (min-width: 1040px) {
    width: 100%;
    max-width: @body-width;
    margin: 0 -10px;
    justify-content: flex-start;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
}

section[data-class="blocextradr"],
section[data-class="blocextraga"] {
  width: 100%;
}

.bloc-extra-outer {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  padding: 0 10px;
  width: 100%;
  max-width: @body-width;
  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
}

.bloc-extra-txt {
  display: block;
  width: 100%;
  max-width: 1000px;
}

@media (min-width: 900px) {
  section[data-class="blocextradr"] {
    .bloc-extra-txt {
      margin-right: 40px;
    }
  }

  section[data-class="blocextraga"] {
    .bloc-extra-txt {
      margin-left: 40px;
    }
  }
}

.titreextra {
  .boldfont;
  .fs-30;
  line-height: 30px;
}

.soustitreextra {
  .basefont;
  .fs-20;
  color: @majeure;
}

.bloc-extra-txt {
  .letexte {
    margin: 0;
  }
}

.bloc-extra-img {
  margin: 20px auto;
  width: 100%;
  max-width: 500px;
  @media (min-width: 900px) {
    margin: 0 auto;
  }
  @media (min-width: 1300px) {
    margin: 0;
  }
}

section[data-class="blocextrabot"],
section[data-class="blocextratop"] {
  width: 100%;
  max-width: 770px;
  .bloc-extra-outer {
    flex-direction: column;
    margin: 20px 0;

    @media (min-width: 1040px) {
      margin: 20px 10px;
    }
  }
  .bloc-extra-img {
    margin: 0 0 20px 0;
  }
  .bloc-extra-txt {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

/****************** PAGE A 2 COLONNES *******************/
.textecol-outer {
  width: 100%;
}

.colx2container {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
  display: flex;
  flex-direction: column;
  @media (min-width: @screen-sm) {
    flex-direction: row;
    align-items: flex-start;
    .letextecol1, .letextecol2 {
      width: 50%;
      i {
        margin: .2em 0 .1em 0;
        font-style: normal;
        .boldfont;
        font-size: 1.6em;
        color: @gray-light;
        line-height: 1.4em;
      }
    }

    .letextecol1 {
      margin-right: 10px;
    }

    .letextecol2 {
      margin-left: 10px;
    }
  }

}

/****************** DIVERS ***************/


.contenu-2bloc {
  max-width: @body-width;
}

section[data-class="imagesimple"] {
  margin: 40px auto;
  width: 100%;
  max-width: @body-width;
  text-align: center;
}