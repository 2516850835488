@import "variables.less";
// @import "/public/stylesheets/bootstrap-3.1.0/mixins.less";
@import "cms-reset.less";
@import "mixins.less";

@import "akeyschool-blog-elements.less";
@import "banner.less";
@import "contact.less";
@import "events.less";
@import "faq.less";
@import "featured-elements.less";
@import "footer.less";
@import "gallery.less";
@import "header.less";
@import "key-features.less";
@import "help-elements.less";
@import "layout-page.less";
@import "license.less";
@import "map-location.less";
@import "multi-sliding.less";
@import "multimedia.less";
@import "nav.less";
@import "news-learn.less";
@import "reset.less";
@import "sections.less";
@import "section-search.less";
@import "section-text-img.less";
@import "signup.less";
@import "social.less";
@import "support.less";
@import "textes.less";
@import "try-buy.less";
@import "extra.less";
@import "video-home.less";

html, body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  .basefont;
  font-size: 16px;
  background-color: white;
  //color: @gristitle;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: auto;
  touch-action: cross-slide-x pan-y;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  scroll-behavior: smooth;
}

.modal-body {
  color: black;
}
section[data-class="sectionronde"]:before,
section[data-class="sectionronde"]:after,
header .header-inner:before,
header .header-inner:after {
  display: initial;
  content: none;
}


a {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.mainnav.fixed + .container-outer {
  margin-top: 60px;
}

.btn-toolbar > .btn-group {
  z-index: 30;
}


.mavvideo iframe,
.mavvideo embed,
.mavvideo object,
.mavvideo video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  max-height: 1010px;
}

.left-inner-addon span.icon-envelope {
  padding: 6px 8px;
  img {
    width: 28px;
  }
}

.logo-header {
  margin-right: auto;
  width: 217px;
  .logo-nukeygara-header {
    z-index: 1;
    display: block;
    margin-top: 7px;
    img {
      width: 170px;
    }
  }
}

.bandeau-outer {
  width: 100%;
  //min-width: 960px;
  padding-top: 60px;
  background-color: black;
  overflow: hidden;
  .bandeau-inner {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
  }
}

.anchor{
  display: block;
  height: @hauteurheader; /*same height as header*/
  margin-top: -@hauteurheader; /*same height as header*/
  visibility: hidden;
}

h3 {
  .fs-28;
  @media(min-width: @screen-sm) {
    .fs-50;
  }
}


.img-home .image {
  text-align: center;
}
.dark-block {
  background-color: @gris;
  padding: 5px;
  div, h1, h2, h3 {
    color:@gristresclair;
  }
  div.cms {
    margin: 30px auto;
    width: 100%;
    max-width: @body-width;
  }
}
.image-centree {
  text-align: center;
}
.page .bandeau-outer {
  @saturation: 0.1;
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(@saturation);
  -webkit-filter: saturate(@saturation);
  .transition(all 500ms);
  &.sature {
    filter: saturate(1);
    -webkit-filter: saturate(1);
  }
}

.titre-outer {
  position: relative;
  width: 960px;
  margin: 0 auto;
  .titre-bandeau {
    position: absolute;
    top: 40px;
    right: 0;
    text-align: right;
    opacity: 1;
    .transition(all 400ms);
    h1 {
      position: static;
      white-space: nowrap;
    }
    &.titre-hidden {
      .translate3d(0, 150px, 0);
      opacity: 0;
    }
  }
}

.intropage {
  .cms-toolbar-zone {
    margin-top: -60px !important;
  }
}

.box-contenu {
  min-height: 30px;
}
/***************************** CONTENU HOME *****************************/
.titre-bandeau {
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.2), 0 -12px 14px 0 rgba(0, 0, 0, 0.2);
}

.titre-bandeau {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  @media(min-width: @screen-md) {
    flex-direction: row;
  }
  .logoh1 {
    text-align: center;
    @media(min-width: @screen-md) {
      text-align: initial;
    }
  }
}

.titre-bandeau {
  position: relative;
  z-index: 4;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media(min-width: @screen-md) {
      flex-direction: row;
    }
    .logoh2 {
      text-align: center;
      img {
        width: 70%;
      }
      @media(min-width: @screen-md) {
        text-align: left;
        img {
          width: 100%;
        }
      }
    }
    h2 {
      margin: 10px 0 0 0;
      .basefont;
      .fs-36;
      text-align: center;
      color: @gristitle;
      @media(min-width: @screen-md) {
        margin: 0 0 0 60px;
      }
      @media(min-width: @screen-lg) {
        .fs-64;
      }
      i {
        font-style: normal;
        color: @majeure;
      }
    }
  }
}

#home-section2 {
  margin-bottom: 100px;
}
/************************************************************************/
/******************************LAYOUT******************************************/
/************************************************************************/
.wrapper {
  width: 100%;
  position: relative;
  background-color: white;
}
.main-outer {
  padding-top: @hauteurheader;
}

.container-outer {
  background-color: white;
  width: 100%;
  height: 100%;
}

.container-page {
  min-height: 300px !important;
}

.contenu {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  .col img {
    max-width: 100%;
    display: inline-block;
  }
  &.navfixed {
    margin-top: 56px;
    .col {
      position: static;
    }
  }
  .full-width {
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
.home .contenu {
  padding-top: 0;
  padding-bottom: 0;
  max-width: none;
}
.contenu-map {
  max-width: none;
}
#cms_user img {
  width: 20px;
}
#home-section1 section:not(.slide):not(.bloc-contenu__txt-img) {
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 1300px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.contenu-libre {
  margin-top: 140px;
}
.introdownload {
  min-height: 50px;
}

.bg-grisfonce {
  position: relative;
  z-index: 10;
}


/****************************** Liste ******************************************/

#articles {
  h2 {
    .basefont;
    color: @gristresclair;
  }
  .mav-article {
    width: 100%;
  }
}

.post {
  margin-bottom: 6px;
}

.articlelist {
  a {
    position: relative;
  }

  .lien-article {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.1em;
    color: white;
  }
}

.lien-article {
  margin-top: 10px;
  padding-right: 10%;
  text-align: right;
  line-height: 0.9;
  a {
    font-size: 1.1em;
  }
  &.back a {
    font-size: 1.2em;
  }
}

/****************************** Edition liste ******************************************/

#nouveau-post-form {
  margin-right: 20px;
  margin-bottom: 20px;
  label {
    margin-top: 10px;
  }
  #titre, textarea {
    color: @grisfonce;
  }
  .ligne-bouton input {
    color: white;
    background-color: @majeure;
    border: none;
  }
}

#editer-post-form {
  input, textarea {
    width: 100%;
  }
  .bloc-detail {
    width: 72.39%
  }
}

.editlist {
  margin-bottom: 20px;
  padding: 10px;
  background-color: @grismoyen;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  .mav-article a {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

/****************************** Détail News ******************************************/

#detail-article {
  h1 {
    margin: 0;
    line-height: 1;
  }
}

/****************************** ARCHIVE ******************************************/
.archive-anne {
  display: none;
}

.conteneurarchive {
  margin-left: 40px;
  h3 {
    font-size: 2em;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      position: relative;
      padding-left: 30px;
      &:before {
        position: absolute;
        left: 15px;
        content: ">";
        font-size: 1.3em;
        color: @gristresclair;
      }
    }
  }
}

/************************************************************************/
/****************************** DOWNLOAD ******************************************/
/************************************************************************/
.champ-download {
  padding-right: 0;
}

/****************************** BOUTON BETA ******************************************/

.btn-red {
  margin: 10px 0;
  text-align: center;
  > div {
    display: inline-block;
    > a {
      display: block;
      cursor: pointer;
      span {
        display: block;
        font-family: 'Nexa-Light', sans-serif;
        font-size: 1.3em;
        text-align: center;
        line-height: 50px;
        letter-spacing: .15em;
        color: white;
        background-color: @majeure;
      }
      &:hover span {
        background-color: @mineure;
      }
    }
  }
}

.btn-beta a {
  width: 383px;
  span {
    padding: 4px 10px 0 60px;
    background-image: url(/public/images/download.png);
    background-repeat: no-repeat;
    background-size: 37px auto;
    background-position: 20px 7px;
  }
}

.btn-history {
  margin-top:20px;
  a {
    width: 383px;
    span {
      padding: 4px 10px 0 10px;
    }
  }
}

/************************************************************************/
/****************************** FOOTER ******************************************/
/************************************************************************/

.partners.contenu {
  padding-top: 0;
}

footer {
  .container {
    width: 960px;
  }
  .liensfooter {
    ul {
      height: 45px;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    li {
      margin: 0;
      padding: 0 61.99999px;
      list-style-type: none;
      display: inline-block;
      font-size: 0.7em;
      line-height: 52px;
      &:first-child {
        padding-left: 0;
        a {
          text-align: left;
        }
      }
      &:last-child {
        padding-right: 0;
        a {
          text-align: right;
        }
      }
      a {
        .basefont;
        color: @gristresclair;
      }
    }
  }

}
.sitemap .container-page {
  margin: 40px auto;
}
#sitemap h1 {
  color: @gristitle;
}
#sitemap {
  width: 100%;
  @media(min-width: @screen-sm) {
    width: 50%;
  }
  li a {
    .basefont;
    color: @gristitle;
    &:hover {
      color: @majeure;
    }
  }
}
.sitemap #cms_user input {
  background-color: @gristresclair;
}

.forgot-password {
  color: @corail;
}

.no-margin-top {
  margin-top: 0;
}
.history-container {
  margin:42px auto 0 auto;
  max-width: 1100px;
}
.tableauto {
  padding: 0 10px;
  @media(min-width: 1300px) {
    padding: 0;
  }
}
table.table {
  width: 100%;
  max-width: @body-width;
  margin: 0 auto;
  color: @majeure;
}
.tableauto td:nth-of-type(2){
  word-break: break-word;
}