.bandeau-home {
  position: relative;
}
.banner__link {
  cursor: pointer;
}
.banner-caption {
  position: relative;
  margin-top: -55px;
  padding: 85px 10px 60px 10px;
  background: rgb(29,29,38);
  background: linear-gradient(180deg, rgba(29,29,38,0) 0%, rgba(29,29,38,0.5956757703081232) 5%, rgba(29,29,38,1) 10%);
  z-index: 5;
  @media(min-width: 992px){
    position: absolute;
    margin-top: -90px;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 15px 10px 10px 10px;
    background: transparent;
  }
  @media(min-width: 1200px) {
    margin-top: -85px;
  }
  @media(min-width: 1300px) {
    margin-top: -80px;
    padding: 55px 0 60px 0;
  }
}
.banner-caption .txt-overlay-outer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  @media(min-width: 992px){
    margin: 0 auto;
    align-items: flex-start;
  }
}
.brand__title {
  margin-top: 20px;
  .boldfont;
  font-size: 32px;
  letter-spacing: 1px;
  color: @mineure;
  @media(min-width: 768px){
    margin-top: 0;
    font-size: 48px;
  }
  @media(min-width: 992px){
    margin-top: 20px;
  }
}
.brand__teasing {
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  .chaney-reg;
  font-size: 27px;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  color: white;
  @media(min-width: 768px){
    font-size: 40px;
    text-align: left;
  }
  @media(min-width: 992px){
    padding-top: 5px;
    padding-bottom: 10px;
  }
  @media(min-width: 1200px){
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 50px;
  }
  @media(min-width: 1300px){
    padding-top: 25px;
    padding-bottom: 30px;
    font-size: 60px;
  }
}
.brand__text {
  padding: 0 30px;
  width: 100%;
  font-size: 18px;
  color: white;
  @media(min-width: 768px){
    padding: 0;
    width: 80%;
  }
  @media(min-width: 920px){
    width: 70%;
  }
  @media(min-width: 992px){
    width: 100%;
    max-width: 540px;
  }
}
.buttons__container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding: 0 30px;
  width: 100%;
  @media(min-width: 768px){
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }
  @media(min-width: 992px){
    margin-top: 5px;
    justify-content: flex-start;
  }
  @media(min-width: 1200px){
    margin-top: 15px;
  }
  @media(min-width: 1300px){
    margin-top: 35px;
  }
}
.button__link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px 15px 30px;
  min-width: 192px;
  .heavyfont;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  border-radius: 14px;
  cursor: pointer;
}
.button__link--1 {
  background-color: @mineure;
  @media(min-width: 768px){
    margin-right: 16px;
  }
  &:hover {
    color: white;
    background-color: @majeure;
  }
}
.button__link--2 {
  margin-top: 32px;
  border: 2px solid white;
  @media(min-width: 768px){
    margin-top: 0;
  }
  &:hover {
    color: @mineure;
    border: 2px solid @mineure;
  }
}
.cms--editing .cms.zone__banner-caption {
  position: absolute!important;
  width: 70%;
  top: 20%;
  left: 10%;
  z-index: 10;
}
.cms--editing .cms.zone__banner-caption section {
  position: absolute!important;
  height: auto;
}
/******************* BANDEAU PAGE *****************/
.banner__layout-width {
  margin: 0 auto;
  padding: 0 6px;
  max-width: 1280px;
  @media(min-width: 1200px){
    padding: 0 10px;
  }
  @media(min-width: 1310px){
    padding: 0;
  }
  @media(min-width: 1320px){
    padding: 0;
  }
}
.bandeau-container {
  position: relative;
}
.bandeauimg {
  min-height: 100px;
  @media(min-width: 320px) {
    min-height: auto;
  }
}
.bandeauimg img {
  width: 100%;
  min-height: 100px;
  @media(min-width: 320px) {
    min-height: auto;
  }
}
.txt-overlay-outer {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  @media(min-width: 540px) {
    top: 12%;
  }
  @media(min-width: 992px) {
    top: 15%;
  }
  @media(min-width: 1300px) {
    padding: 0;
    //margin: 0 calc(~"(100% - 1280px) / 2");
  }
}
.full-width .txt-overlay-outer {
  @media(min-width: 1300px) {
    margin: 0 calc(~"(100% - 1280px) / 2");
  }
}
.header__banner--small {
  margin-top: 32px;
  img {
    border-radius: 14px;
  }
}
#top-page {
  margin-top: -80px;
  padding-top: 80px;
}
.banner__layout-width .txt-overlay-outer {
  position: absolute;
  display: flex;
  top: 0;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: #1d1d26;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(50%, rgba(29,29,38,0)),color-stop(75%, rgba(29,29,38,.59567577)),color-stop(100%, #1d1d26));
  background: -webkit-linear-gradient(top,rgba(29,29,38,0) 50%,rgba(29,29,38,.59567577) 75%,#1d1d26 100%);
  background: -moz-linear-gradient(top,rgba(29,29,38,0) 50%,rgba(29,29,38,.59567577) 75%,#1d1d26 100%);
  background: -o-linear-gradient(top,rgba(29,29,38,0) 50%,rgba(29,29,38,.59567577) 75%,#1d1d26 100%);
  background: linear-gradient(180deg,rgba(29,29,38,0) 50%,rgba(29,29,38,.59567577) 75%,#1d1d26 100%);
  border-radius: 14px;
  @media(min-width: 320px) {
    padding: 10px;
  }
  @media(min-width: 480px) {
    padding: 48px;
  }
}
h1.txt-overlay {
  margin-top: 15px;
  .chaney-reg;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  color: white;
  text-shadow: -10px 10px 12px rgba(42,42,57, 0.3);
  @media(min-width: 380px) {
    font-size: 15px;
  }
  @media(min-width: 540px) {
    margin: 0 0 15px 0;
    font-size: 24px;
  }
  @media(min-width: 768px) {
    margin: 0 0 25px 0;
    font-size: 32px;
    line-height: 1;
  }
  @media(min-width: 992px) {
    margin: 0 0 45px 0;
    font-size: 40px;
  }
  @media(min-width: 1200px) {
    margin: 0 0 55px 0;
    font-size: 50px;
  }
  @media(min-width: 1280px) {
    font-size: 60px;
  }
}
.desc-overlay {
  font-size: 12px;
  color: white;
  @media(min-width: 480px) {
    font-size: 15px;
  }
  @media(min-width: 992px) {
    width: 100%;
    max-width: 540px;
    font-size: 18px;
  }
}
.banner__layout-width h1.txt-overlay {
  margin-top: 0;
  position: relative;
  @media(min-width: 480px) {
    margin-top: 20px;
  }
}
.banner__label {
  margin-right: auto;
  padding: 5px 6px 2px 6px;
  .boldfont;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  background-color: @mineure;
  border-radius: 5px;
  @media(min-width: 320px){
    font-size: 15px;
  }
  @media(min-width: 380px){
    padding: 5px 0 2px 0;
  }
  @media(min-width: 480px){
    font-size: 16px;
  }
}
.accroche-page {
  position: relative;
  display: flex;
  padding-right: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 200px;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;
  @media(min-width: 1300px) {
    padding-right: 0;
  }
  h1, h2 {
    width: 100%;
    max-width: @body-width;
    .basefont;
    .fs-28;
    line-height: 2rem;
    text-align: center;
    color: @gristitle;
    @media(min-width: @screen-md) {
      .fs-36;
      line-height: 2.25rem;
    }
    @media(min-width: @screen-lg) {
      margin: 0 auto 0 auto;
      .fs-64;
      text-align: right;
    }
    i {
      font-style: normal;
      color: @majeure;
    }
  }
}

.cms--editing .txt-overlay-outer{
  top: 0;
  left: 5%;
}
