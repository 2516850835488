.basefont { font-family: 'Nexa-Light', sans-serif;}
.bookfont {font-family: 'Nexa-Book', sans-serif;}
.boldfont {
  font-family: 'Nexa-Book', sans-serif;
  font-weight: 700;
}
.blackfont {font-family: 'NexaBlack', sans-serif;}
.heavyfont {font-family: 'NexaHeavy', sans-serif;}
.footerfont {font-family: 'Roboto Condensed', sans-serif;}

.chaney-reg { font-family: 'chaneyregular', sans-serif;}
.chaney-ext {font-family: 'chaneyextended', sans-serif;}
.chaney-ultraext {font-family: 'chaneyultra_extended', sans-serif;}
.chaney-wide {font-family: 'chaneywide', sans-serif;}

@rouge:#930000;
@rouge2:#aa0103;
@corail: #f44336;
@grisfonce:#3b3b4e;
@gris:#343434;
@grismoyen: #474747;
@gristitle: #666666;
@grisbleufonce: #4e4e5c;
@grisbleu: #9e9eb2;
@grisclair: #e2e2e9;
@gristresclair: #eeeeee;
@dark-blue: #2a2a39;
@blue: #03a9f4;
@green: #00e676;

@majeure:@dark-blue;
@mineure:@corail;

@navcolor:       @majeure;
//@navcolor:       #2a2a39;
@light-navcolor: #3b3b4e;
@grisnav:        #c1c1c1;


.texte__souligne-rouge {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px){
    display: inline;
  }
  &:after {
    margin-top: 10px;
    width: 160px;
    height: 4px;
    background-color: @mineure;
    content: '';
    @media(min-width: 768px){
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }
}
.texte__souligne-bleu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px){
    display: inline;
  }
  &:after {
    margin-top: 10px;
    width: 160px;
    height: 4px;
    background-color: @blue;
    content: '';
    @media(min-width: 768px){
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }
}
.texte__souligne-vert {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px){
    display: inline;
  }
  &:after {
    margin-top: 10px;
    width: 160px;
    height: 4px;
    background-color: @green;
    content: '';
    @media(min-width: 768px){
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }
}
.Rouge {
  color:  @mineure;
}
.Bleu {
  color: @blue;
}
.Vert {
  color: @green;
}
.Texte-barre {
  text-decoration: line-through;
}

/* Animations */
@transition-easing: cubic-bezier(0.4, 0, 0.2, 1);

.ombre {
  box-shadow: 0 5px 20px rgba(0,0,0,0.8);
}
.rouge {
  color:@corail;
 }
.corail {
  color: @corail;
}
.grisclair {
  color:@grisclair;
}
.bg-gristresclair {
  background-color:@gristresclair;
}
.bg-grisfonce {
  background-color: @grisfonce;
}

.bg-gris {
  background-color: @gris;
}
.bg-blanc {
  background-color: white;
}
.gridmarginpaddingreset { /*pour enlever les 15px de bootstrap*/
  .row {
    margin-left:0;
    margin-right:0;
    .col {
      padding-left:0;
      padding-right:0;
    }
  }
}
.translate (@x: 0, @y:0, @z:0) {
  -webkit-transform:translate3d(@x, @y, @z);
  -moz-transform:translate3d(@x, @y, @z);
  -ms-transform:translate(@x, @y);
  transform:translate3d(@x, @y, @z);
}

.nav-scale (@x: 1, @y:1, @z:1) {
  -webkit-transform:scale3d(@x, @y, @z);
  -moz-transform:scale3d(@x, @y, @z);
  -ms-transform:scale(@x, @y);
  transform:scale3d(@x, @y, @z);
}

.hide-backface {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.transform-transition (@duration) {
  -webkit-transition: -webkit-transform @duration ease;
  -moz-transition: -moz-transform @duration ease;
  -ms-transition: -moz-transform @duration ease;
  transition: transform @duration ease;
}

.nav-shadow {
  -webkit-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.40);
  -moz-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
  -ms-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
  box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.90);
}

/****************************** FONT-SIZE *******************************/
.fs-8 {font-size:0.5rem;  }
.fs-9 {font-size:0.5625rem;  }
.fs-10 {font-size:0.625rem;  }
.fs-11 {font-size:0.6875rem;  }
.fs-12 {font-size:0.75rem;  }
.fs-13 {font-size:0.8125rem;  }
.fs-14 {font-size:0.875rem;  }
.fs-15 {font-size:0.9375rem;  }
.fs-16 {font-size:1rem;  }
.fs-17 {font-size:1.0635rem;  }
.fs-18 {font-size:1.125rem;  }
.fs-19 {font-size:1.1875rem;  }
.fs-20 {font-size:1.25rem;  }
.fs-21 {font-size:1.3125rem;  }
.fs-22 {font-size:1.375rem;  }
.fs-23 {font-size:1.4375rem;  }
.fs-24 {font-size:1.5rem;  }
.fs-25 {font-size:1.5625rem;  }
.fs-26 {font-size:1.625rem;  }
.fs-27 {font-size:1.6875rem;  }
.fs-28 {font-size:1.75rem;  }
.fs-29 {font-size:1.8125rem;  }
.fs-30 {font-size:1.875rem;  }
.fs-32 {font-size:2rem;  }
.fs-34 {font-size:2.125rem;  }
.fs-36 {font-size:2.25rem;  }
.fs-38 {font-size:2.375rem;  }
.fs-40 {font-size:2.5rem;  }
.fs-42 {font-size:2.625rem;  }
.fs-44 {font-size:2.75rem;  }
.fs-46 {font-size:2.875rem;  }
.fs-48 {font-size:3rem;  }
.fs-50 {font-size:3.125rem;  }
.fs-52 {font-size:3.25rem;  }
.fs-56 {font-size:3.5rem;  }
.fs-58 {font-size:3.625rem;  }
.fs-60 {font-size:3.75rem;  }
.fs-64 {font-size:4rem;  }
.fs-68 {font-size:4.25rem;  }
.fs-72 {font-size:4.5rem;  }
.fs-80 {font-size:5rem;  }
.fs-84 {font-size:5.25rem;  }
.fs-88 {font-size:5.5rem;  }
.fs-96 {font-size:6rem;  }
.fs-110 {font-size:6.875rem;  }




// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
//   supported browsers that have box shadow capabilities now support the
//   standard `box-shadow` property.
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
  box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
  transition: @transition;
}
.transition-property(@transition-property) {
  -webkit-transition-property: @transition-property;
  transition-property: @transition-property;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
  transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
  -webkit-transition-duration: @transition-duration;
  transition-duration: @transition-duration;
}
.transition-transform(@transition) {
  -webkit-transition: -webkit-transform @transition;
  -moz-transition: -moz-transform @transition;
  -o-transition: -o-transform @transition;
  transition: transform @transition;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
  transition: @transition;
}
.transition-property(@transition-property) {
  -webkit-transition-property: @transition-property;
  transition-property: @transition-property;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
  transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
  -webkit-transition-duration: @transition-duration;
  transition-duration: @transition-duration;
}
.transition-transform(@transition) {
  -webkit-transition: -webkit-transform @transition;
  -moz-transition: -moz-transform @transition;
  -o-transition: -o-transform @transition;
  transition: transform @transition;
}

// Transformations
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
  -ms-transform: rotate(@degrees); // IE9 only
  transform: rotate(@degrees);
}
.scale(@ratio; @ratio-y...) {
  -webkit-transform: scale(@ratio, @ratio-y);
  -ms-transform: scale(@ratio, @ratio-y); // IE9 only
  transform: scale(@ratio, @ratio-y);
}
.translate(@x; @y) {
  -webkit-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y); // IE9 only
  transform: translate(@x, @y);
}
.skew(@x; @y) {
  -webkit-transform: skew(@x, @y);
  -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
  transform: skew(@x, @y);
}
.translate3d(@x; @y; @z) {
  -webkit-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}

.rotateX(@degrees) {
  -webkit-transform: rotateX(@degrees);
  -ms-transform: rotateX(@degrees); // IE9 only
  transform: rotateX(@degrees);
}
.rotateY(@degrees) {
  -webkit-transform: rotateY(@degrees);
  -ms-transform: rotateY(@degrees); // IE9 only
  transform: rotateY(@degrees);
}
.perspective(@perspective) {
  -webkit-perspective: @perspective;
  -moz-perspective: @perspective;
  perspective: @perspective;
}
.perspective-origin(@perspective) {
  -webkit-perspective-origin: @perspective;
  -moz-perspective-origin: @perspective;
  perspective-origin: @perspective;
}
.transform-origin(@origin) {
  -webkit-transform-origin: @origin;
  -moz-transform-origin: @origin;
  -ms-transform-origin: @origin; // IE9 only
  transform-origin: @origin;
}

// Animations
.animation(@animation) {
  -webkit-animation: @animation;
  animation: @animation;
}
.animation-name(@name) {
  -webkit-animation-name: @name;
  animation-name: @name;
}
.animation-duration(@duration) {
  -webkit-animation-duration: @duration;
  animation-duration: @duration;
}
.animation-timing-function(@timing-function) {
  -webkit-animation-timing-function: @timing-function;
  animation-timing-function: @timing-function;
}
.animation-delay(@delay) {
  -webkit-animation-delay: @delay;
  animation-delay: @delay;
}
.animation-iteration-count(@iteration-count) {
  -webkit-animation-iteration-count: @iteration-count;
  animation-iteration-count: @iteration-count;
}
.animation-direction(@direction) {
  -webkit-animation-direction: @direction;
  animation-direction: @direction;
}
