.image-article {
  margin: 0 auto;
  padding: 10px;
  @media(min-width: 768px){
    margin: 0;
    padding: 16px;
  }
}
.image-article--small {
  max-height: 520px;
  @media(min-width: 620px){
    flex: 1 0 calc(~"100% / 3");
    flex-basis: calc(~"100% / 3");
    max-width: 352px;
    max-height: none;
  }
}
.image-article--medium {
  @media(min-width: 620px){
    flex: 1 0 50%;
  }
}
.image-article__container {
  position: relative;
  height: 100%;
}