section[data-class="titre-section"],
section[data-class="titre-section-button"],
section[data-class="soustitre-section"] {
  width: 100%;
}
.separating-line {
  width: 100%;
  div {
    margin: 70px auto 40px auto;
    width: 50%;
    height: 8px;
    background-color: @majeure;
  }
}
.separating-line--fullwidth {
  width: 100%;
  div {
    margin: 35px auto 30px auto;
    width: 100%;
    height: 3px;
    background-color: @grisclair;
  }
}
.titresection,
.titresection-h2 {
  margin: 25px auto;
  max-width: 1280px;
  .chaney-reg;
  .fs-28;
  line-height: 1;
  text-align: center;
  color: @majeure;
  @media (min-width: 992px) {
    margin: 15px auto 50px auto;
    font-size: 48px;
  }
}
.title-btn__container {
  display: flex;
}

.titre-section__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
  @media (min-width: 580px) {
    flex-direction: row;
  }
  @media (min-width: 992px) {
    margin: 55px auto 50px auto;
  }
}
.titre-section__container .titresection {
  margin: 0;
}


.btn-plus__link {
  display: block;
  margin-top: 16px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  @media (min-width: 580px) {
    margin-top: 0;
    margin-left: 32px;
  }
  &:hover {
    .btn-plus__text {
      color: @mineure;
      border: 2px solid @mineure;
    }
  }
}
.btn-plus__text {
  display: flex;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  .boldfont;
  line-height: 1;
  font-size: 32px;
  color: @blue;
  border: 2px solid @blue;
  border-radius: 14px;
  transition: color 160ms @transition-easing, border 160ms @transition-easing;
}

.button-editable {
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  max-width: 1280px;
}
.button-editable--centered {
  text-align: center;
}
.btn__link {
  display: block;
  margin: 25px auto;
  padding: 25px 0;
  max-width: 90%;
  @media (min-width:550px) {
    max-width: 50%;
  }
  @media (min-width:768px) {
    display: inline-block;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    margin: 65px 0;
    padding: 0;
  }
}
.btn__text {
  padding: 20px 20px 14px 20px;
  .heavyfont;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  background-color: @mineure;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 160ms @transition-easing;
  &:hover{
    background-color: @majeure;
  }
  &:empty {
    display: none;
  }
}
section[data-class="boutonloadmore"] {
  text-align: center;
}
.btn__text--light {
  color: @majeure;
  background-color: white;
  box-shadow: 0 0 0 2px @majeure inset;
  transition: color 160ms @transition-easing, background-color 160ms @transition-easing;
  &:hover{
    color: white;
    background-color: @majeure;
  }
}

.dots-editable {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1280px;
  @media(min-width: 1300px) {
    padding: 0;
  }
}
.txt-btn__w-bgd {
  width: 100%;
  background-color: @grisclair;
}
.txt__w-bgd .colored-bgd__container .rich-text {
  margin: 0 10px;
  padding: 20px 10px;
  .boldfont;
  text-align: center;
  background-color: @grisclair;
  border-radius: 14px;
  @media(min-width: 1300px) {
    margin: 0;
  }
}
.colored-bgd__img {
  padding: 40px 20px;
  &--small img {
    width: auto;
    max-height: 320px;
  }
}
.colored-bgd__container {
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
  .rich-text {
    padding: 40px 10px 10px 10px;
    .bookfont;
    font-size: 18px;
    color: @majeure;
    @media(min-width: 768px) {
      padding: 40px 10px 0 10px;
    }
    @media(min-width: 1300px) {
      padding: 55px 0 0 0;
    }
    &:empty {
      display: none;
    }
    a {
      color: @mineure;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.cms--editing .btn__text:empty,
.cms--editing .rich-text:empty {
  display: block;
}

// Textes des pages Articles
section[data-class="titre1-article"],
section[data-class="titre1-article--bis"],
section[data-class="titre2-article--bis"],
section[data-class="titre3-article"] {
  width: 100%;
}
.date-editable {
  margin-bottom: 25px;
  .boldfont;
  font-size: 16px;
}
.keyword-editable {
  margin: 20px 0;
  padding: 4px 5px 0 5px;
  .boldfont;
  font-size: 14px;
  text-transform: uppercase;
  color: @majeure;
  background-color: @grisclair;
  border-radius: 5px;
  cursor: pointer;
  transition: color 160ms @transition-easing, background-color 160ms @transition-easing;
  &.blog-type {
    background-color: @mineure;
    color: white;
  }
}
.text-background {
  margin: 20px 0;
  padding: 24px;
  background-color: @grisclair;
  border-radius: 14px;
  @media(min-width: 768px) {
    margin: 50px 0;
  }
  &--centered {
    text-align: center;
  }
}
.titre-article {
  margin: 25px 0 10px 0;
  .chaney-reg;
  font-size: 24px;
  text-transform: uppercase;
  color: @majeure;
}
.soustitre-article {
  margin: 14px 0 24px 0;
  .boldfont;
  font-size: 18px;
  text-transform: uppercase;
  color: @majeure;
}
.article__main-wrapper .letexte {
  .bookfont;
  font-size: 18px;
  color: @majeure;
  ul li {
    position: relative;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 30px;
    @media(min-width: 768px) {
      margin-left: 60px;
    }
    &:before {
      position: absolute;
      top: 2px;
      float: none;
      margin-top: 0;
      margin-left: -30px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      content: '';
    }
  }
}
.letexte i {
  .basefont;
  font-size: 20px;
}
// Couleurs selon pages articles
.article__main-wrapper--akeyschool .keyword-editable.blog-type,
.article__main-wrapper--akeyschool .keyword-editable:hover {
  color: white;
  background-color: @blue;
}
.page {
  .article__main-wrapper--akeyschool {
    .letexte a, a {
      color: @blue;
    }
  }
}
.article__main-wrapper--akeyschool ul li {
  &:before {
    background-color: @blue;
  }
}
.article__main-wrapper--blog ul li {
  &:before {
    background-color: @mineure;
  }
}
.article__main-wrapper--akeyschool section[data-class="boutoncouleur"] .btn__text {
  background-color: @blue;
}
.article__main-wrapper--blog section[data-class="boutoncouleur"] .btn__text {
  background-color: @mineure;
}
section[data-class="boutoncouleur"] .btn__text:hover {
  background-color: @majeure;
}

.cms--editing .dots-editable {
  margin-top: 40px;
}