.news .main-outer>.bandeau-container {
  display: none;
}
.main-outer>.bandeau-container img {
  width: 100%;
}
.news .contenu-news {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
}
.news .maincontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 1040px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: initial;
  }
  @media(min-width: 1560px) {
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
}
.news .articlelist {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 760px;
  .bloc-accroche.mav-article {
    margin: initial;
  }
}
.articlelist:nth-child(n+3) {
  max-width: 500px;
}
.news .content-head {
  width: 100%;
}
.contenu-news .editlist {
  margin: 0 auto;
  max-width: 760px;
  .more a {
    display: block;
    color: white;
    text-align: right;
    &:hover {
      color: @majeure;
    }
  }
}
.detail-post {
  max-width: 1200px;
  &.contenu {
    margin: 40px auto;
  }
  .titre-article h1 {
    color: @gristitle;
  }
  h3 {
    .fs-24;
  }
  a {
    .boldfont;
    color: @majeure;
  }
}

/************* BLOG NEWS ****************/
.bloc-ombre {
  display: flex;
  flex-direction: column;
  .colimg {
    margin-bottom: 30px;
    order: 0;
    width: 100%;
    max-width: 400px;
    text-align: right;
  }
  .coltxt {
    order: 1;
  }
  @media (min-width: @screen-md) {
    flex-direction: row;
    justify-content: space-between;
    .colimg {
      order: 1;
    }
    .coltxt {
      margin-right: 40px;
      order: 0;
    }
  }
}
section[data-class="contenuimgombredroite"] {
  figure {
    box-shadow: none;
  }
}
/****************** ARTICLES *****************/
.press .suite-contenu, .home .suite-contenu, .blog  .suite-contenu {
  margin-bottom: 40px;
  max-width: @body-width;
  padding: 0 10px;
  .article-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 1300px) {
      padding: 0;
    }
  }
}
section[data-class="mediumimagearticle"] {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 760px;
}
section[data-class="smallimagearticle"] {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 500px;
}
.press, .home {
    .article .legendevig {
    flex-direction: column;
    width: 100%;
    height: 75px;
    justify-content: center;
    .boldfont;
    text-align: center;
  }
}
/****************** LEARN *****************/
section[data-class="videoflag"] {
  margin-bottom: 10px;
  padding-left: 10px;
  width: 100%;
  >div{
    display: flex;
    flex-direction: row;
  }
  img {
    width: 56px;
    height: 40px;
  }
  .lang-flag {
    margin-top: 6px;
    margin-left: 5px;
    .boldfont;
    .fs-20;
    color: @gristitle;
    @media(min-width: @screen-sm) {
      margin-top: 0;
      margin-left: 15px;
      .fs-36;
    }
    b {
      color: @majeure;
    }
  }
}
.video-flag {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  a {
    margin: 0 20px;
  }
  img {
    width: 56px;
    height: 40px;
  }
}
.contenu-en, .contenu-es {
  margin: 0 auto 40px auto;
  width: 100%;
  max-width: @body-width;
  .video-en, .video-es {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 500px) {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    @media (min-width: 1560px) {
      margin-left: -10px;
      margin-right: -10px;
    }
    section[data-class="modulevideo1"].bloc-video {
      margin-right: 0;
      margin-left: 0;
    }
    .bloc-accroche.mav-article-small, .bloc-video .bloc-gallery-outer {
      margin: 0 10px 20px 10px;
      padding-left: 0;
      padding-right: 0;
      max-width: 300px;
      @media(min-width: 500px) {
        max-width: 240px;
      }
      .legendevig {
        height: 50px;
      }
      .legendevig .titre-vig {
        .fs-14;
      }
      .legendevig .soustitre-vig {
        .fs-13;
      }
    }
  }
}
.learn-akeytsu section[data-class="textesimple"] {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
/*********** DOCUMENTATION & RELEASE *************/
.marge-bas {
  margin-top: 70px;
}
/*********** SURCHARGE FANCYBOX ******************/
.fancybox-opened .fancybox-skin {
  border-radius: 0;
}
