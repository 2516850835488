//.akeyschool .flex-wrap__inner section:not(.akeyschool-tile),
//.blog .flex-wrap__inner section:not(.blog-tile){
//  padding: 0 10px;
//  @media(min-width: 1300px) {
//    padding: 0;
//  }
//}
//.akeyschool .flex-wrap__inner section.akeyschool-tile,
//.blog .flex-wrap__inner section.blog-tile {
//  @media(min-width: 1300px) {
//    padding: 0 16px
//  }
//}
.akeyschool section[data-class="titre-section-button"] {
  margin-top: -150px;
  padding-top: 150px;
}
.akeyschool-tile {
  display: flex;
  margin: 20px auto;
  padding: 0 16px;
  width: 100%;
  max-width: 437px;
  min-height: 517px;
  flex-shrink: 0;
  scroll-snap-align: start;
  @media(min-width: 768px) {
    margin: 40px 0;
    padding: 0 6px;
    flex: 1 1 calc(~"100% / 3");
    flex-basis: calc(~"100% / 3");
  }
  @media(min-width: 992px) {
    min-height: 617px;
  }
  @media(min-width: 1200px) {
    padding: 0 16px;
  }
}
.akeyschool-tile__inner,
.blog-tile__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 14px;
  overflow: hidden;
  background-color: @majeure;
  cursor: pointer;
}
.akeyschool-tile .multi-slide__data {
  padding: 24px;
  @media(min-width: 992px) {
    padding: 24px 48px;
  }
}
.tile__title {
  margin: 16px 0 24px 0;
  .chaney-reg;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
  color: white;
}
.tile__text {
  padding-right: 15px;
  font-size: 18px;
  line-height: 1.2;
  color: white;
}
.tile__label {
  display: inline-block;
  padding: 5px 6px 2px 6px;
  .boldfont;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  background-color: @blue;
  border-radius: 5px;
}
.blog-tile .tile__label {
  background-color: @mineure;
}
.blog-tile {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  padding: 0 16px;
  width: 100%;
  max-width: 656px;
  min-height: 450px;
  flex-shrink: 0;
  scroll-snap-align: start;
  @media(min-width: 700px) {
    margin: 6px 0;
    padding: 0 6px;
    flex: 0 1 50%;
  }
  @media(min-width: 992px) {
    min-height: 624px;
  }
  @media(min-width: 1200px) {
    margin: 16px 0;
    padding: 0 16px;
  }
}
.blog-tile__inner {
  .multi-slide__data {
    padding: 24px;
    position: relative;
    @media(min-width: 992px) {
      padding: 24px 48px;
    }
  }
}
.blog-tile__inner .tagline__date {
  top: 24px;
  right: 48px;
  bottom: auto;
}
.cms--editing .blog-tile {
  max-width: 50%;
}
.cms--editing.akeyschool .akeyschool-tile {
  max-width: 33%;
}