/******************** EVENTS ****************/
.ligne-events-outer {
  width: 100%;
  max-width: @body-width;
  margin: 0 auto;
}
.logo-event {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
}
.events .contenu-libre, .press .contenu-libre {
  margin-top: 0;
}
.events section[data-class="titre-section"] {
  width: 100%;
  @media (min-width: @screen-md) {
    margin-left: 30px;
    text-align: left;
  }
  @media (min-width: 1300px) {
    margin-left: 0;
  }
}
section[data-class="imagelogo"] {
  text-align: center;
  width: 100%;
  @media (min-width: @screen-md) {
    margin-left: 30px;
    text-align: left;
  }
  @media (min-width: 1300px) {
    margin-left: 0;
  }
}
.ligne-events {
  display: flex;
  margin-left: -30px;
  margin-right: -30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 390px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  @media(min-width: @screen-lg) {
    justify-content: flex-start;
  }
}
.pola-event {
  margin: 40px 30px 0 30px;
  display: flex;
  //width: 360px;
  flex-direction: column;
  @media (min-width: @screen-xs) {
    width: 330px;
  }
}
.pola-outer {
  display: flex;
  margin: 0 auto 30px auto;
  padding: 10px 15px 15px 15px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.2);
  @media(min-width: 460px) {
    padding: 10px 15px 60px 15px;
    width: 330px;
    height: 370px;
  }
}
.pola-txt-outer {
  padding-top: 12px;
  text-align: center;
  color: @gristitle;
  .pola-txt-title {
    .boldfont;
    .fs-26;
    line-height: 1.5rem;
    b {
      color: @majeure;
    }
  }
  .pola-txt-subtitle {
    .basefont;
    .fs-16;
  }
}
.pola-txt {
  .basefont;
  .fs-16;
  line-height: 20px;
  text-align: justify;
}