/************************* BLOC SOCIAL *********************/

.reseau-social {
  display: flex;
  flex-direction: row;
  padding: 0;
  list-style-type: none;
  @media(min-width: 768px) {
    margin-left: -16px;
  }
  li {
    margin: 0 5px;
    background-color: @gristitle;
  }
}
.socials-networks__item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7px;
  width: 32px;
  height: 32px;
  @media(min-width: 500px) {
    margin: 0 12px;
  }
  @media(min-width: 768px) {
    margin: 0 16px;
  }
  &:hover {
    .socials-networks__icon {
      fill: @grisbleu;
    }
  }
}
.socials-networks__icon {
  width: 100%;
  height: 100%;
  fill: @grisbleufonce;
  transition: fill 160ms @transition-easing;
}
a.picto-account {
  color: white;
  &:hover {
    color: white;
  }
}

// PICTOS SOCIAL EN STICKY VERTICAL
.reseau-social--column {
  display: flex;
  padding-top: 16px;
  justify-content: center;
  @media(min-width: 768px) {
    position: sticky;
    top: calc(~"50% - 64px");
    margin-left: 0;
    padding-top: 0;
    flex-direction: column;
    align-items: center;
  }
}
.reseau-social--column .socials-networks__item {
  position: relative;
  margin-bottom: 16px;
  width: 28px;
  height: 28px;
  cursor: pointer;
/*  &.socials-networks__item--twitter {
    pointer-events: none;
    touch-action: none;
  }*/
}
.fb-share-button.fb_iframe_widget,
#twitter-widget-0 {
  position: absolute!important;
  margin: 12px;
  width: 28px!important;
  height: 28px!important;
  opacity: 0;
  cursor: pointer!important;
  z-index: 5;
  @media(min-width: 992px){
    margin: 0 16px;
  }
  > * {
    width: 28px!important;
    height: 28px!important;
    cursor: default!important;
  }
  iframe, iframe > * {
    width: 28px!important;
    height: 28px!important;
    cursor: pointer!important;
  }
}
.fb-share-button.fb_iframe_widget {
  top: 10px;
  margin-bottom: 0!important;
  @media(min-width: 992px){
  top: 6px;
  }
  span {
    height: 28px!important;
  }
}
#twitter-widget-0  {
  top: 10px;
  @media(min-width: 992px){
    top: 49px;
  }
}