
@hauteurheader:80px;
@hauteurbandeau:260px;
@hauteurbandeauhome:520px;

header {
  ul.social {
    width:300px;
    float:right;
    text-align:right;
    list-style:none;
    padding:0;
    margin: 8px 0 0 0;
    cursor: default;
    li {
      display:inline-block;
      margin-left:15px;
      a {
        display:inline-block;
        cursor: pointer;
        .contact { width: 45px; }
        .fb { width: 30px; }
        .twitter { width: 35px; }
        .instagram { width: 35px; }
        .discord { width: 35px; }
      }
    }
  }
}

header {
  position: fixed;
  z-index: 20;
  background-color: @navcolor;
  height: @hauteurheader;
  width: 100%;
  //overflow: hidden;
  .reseau-social {
    width: 300px;
    float: right;
    text-align: right;
    list-style: none;
    padding: 0;
    margin: 8px 15px 0 0;
    cursor: default;
    li {
      display: inline-block;
      margin-left: 15px;
      a {
        display: inline-block;
        cursor: pointer;
        .glyphicon {
          top: 6px;
        }
        .glyphicon-user {
          font-size: 1.3em;
          color: white;
          &.logged {
            color: @majeure;
          }
        }
        .log-out {
          top: 3px;
        }
      }
    }
  }
}
.header-inner {
  max-width: 1280px;
  margin: 0 auto;
  height: @hauteurheader;
  @media(min-width: 992px) {
    padding: 0 10px;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
}
.logo-nukeygara-header>img {
  width: 170px;
  @media(min-width: @body-width) {
    width: 235px;
  }
}