.navbar:before,
.navbar-header:before,
.navbar:after,
.navbar-header:after {
  content: none;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/****************************** CMS RESET *******************************/
.editing-guide {
  display: none;
  font-size: 10px;
  font-style: italic;
  color: grey;
}

.cms--editing .main-content {
  min-height: 100vh;
}

.cms--editing .editing-guide {
  margin-top: 50px;
  padding-left: 20px;
  display: block;
  align-self: flex-start;
  text-align:left;
  .fs-12;
  font-style:italic;
  color:grey;
}

.editing-guide {
  display: none;
}


.editing .slider-container #slider {
  overflow-x: hidden;
}
.editing .bandeau-outer .bandeau-inner {
  min-height: 150px;
  .cms-toolbar-zone {
    margin-top: 0!important;
  }
  section[data-class="imagebandeau"]>.cms-toolbar-zone {
    margin-left: 50px;
  }
}
.editing .contenu-page {
  min-height: 150px;
  .cms {
    min-height: 40px;
  }
}
body.editing {
  .slider-container .container {
    #slider {
      section[data-class='slider'] {
        .cms-toolbar-zone {
          margin-top: 0;
          margin-left: 35px;
          width: 350px;
        }
      }
    }
  }
}
.editing .col-fo {
  min-height: 50px;
}
.editing .img-rond > .cms-wrap > .btn-group {
  left: 50%;
}
.news.editing .contenu-news .editlist {
  margin: 20px auto;
}
/*.page.editing .main-outer {
  padding-top: 100px;
  .bandeau-inner {
    min-height: 170px;
  }
}

.editing .contenu .cms {
  padding-top: 30px;
  padding-bottom: 30px;

}
.contenu .cms:empty {
    display: none;
}
.contenu:empty {
    display: none;
    min-height: 0;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
}*/
/******************************Correctifs cms ******************************************/
ul, ol {
  margin-block-start: 0;
  padding-inline-start: 0;
}
#cms_user {
  td {
    padding:4px 8px;
    label {
      .basefont;
      font-weight:200;
      font-size:0.8em;
    }
  }
  .btn {
    padding: 6px 4px 4px 4px;
    color: white;
    background-color: @majeure;
    border-color:@majeure;
    &:hover,&:focus {
      color:@gristitle;
    }
  }
  input {
    background-color:@grismoyen;
    color:@majeure;
  }
}


button#nouveau-post {
  margin-bottom: 20px;
  color: @gristresclair;
  background-color: @grisfonce;
}

.btn-keep {
  padding-left: 0;
  z-index: 10;
  .btn.btn-danger {
    width: 100px;
    height: 34px;
    .boldfont;
    padding: 18px 5px 14px 5px !important;
    font-size: 16px !important;
    line-height: 0 !important;
    border: none;
    border-radius: 0;
  }
}

.btn-toolbar>.btn, .btn-toolbar>.btn-group, .btn-toolbar>.input-group {
  z-index: 2;
}
.cms_action {
  padding-top: 6px;
  color: white;
  background-color: @majeure;
  font-size:0.8em;
  border:none;
  line-height:1.3em;
}
.modal-backdrop, .modal-backdrop.fade.in {
  opacity: .8;
  filter: alpha(opacity=80);
}
.cms-section .flexslider .slides > li {
  display:block;
}
.dropdown-menu {
  z-index:3;
}
ul.dropdown-menu {
  height:auto !important;
}
section ul.dropdown-menu li,
.dropdown-menu li,
.dropdown-menu li.dropdown-submenu {
  background:none !important;
  padding:0 !important;
  font-size:14px;
  line-height:14px;
  text-align:left;
  a {
    display:block !important;
    background-color:white !important;
    background-image:none;
    padding:2px 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    &:hover {
      background-color:dimgrey  !important;
      color:white;
    }
  }
}

.btn {
  font-size:12px;
  line-height:18px;
  padding:4px;
}
.editing .map-overlay {
  pointer-events: initial;
}
.editing .contenu-gallery .cms-toolbar-zone {
  display: block!important;
}