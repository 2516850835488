.help-subjects__zone {
  margin: 16px auto;
  @media(min-width: 992px) {
    margin: 44px auto;
  }
  .flex-wrap__container {
    max-width: 1312px;
  }
  .flex-wrap__inner {
    @media(min-width: 500px) {
      justify-content: center;
    }
  }
}
.help-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0;
  background-image: url(/public/images/shapes/bgd-curves.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: @grisclair;
  @media(min-width: 1100px) {
    padding: 75px 0;
  }
  .titresection {
    margin: 20px auto 55px auto;
    @media(min-width: 1100px) {
      margin: 55px auto 100px auto;
    }
  }
}
.nav-inside__container {
  display: flex;
  margin: 16px 0;
  padding: 0 16px;
  width: 100%;
  flex-shrink: 0;
  @media(min-width: 500px) {
    max-width: 380px;
  }
  @media(min-width: 600px) {
    margin: 10px 0;
    padding: 0 10px;
    max-width: 336px;
    flex: 1 1 50%;
  }
  @media(min-width: 768px) {
    margin: 16px 0;
    padding: 0 16px;
    max-width: none;
  }
  @media(min-width: 940px) {
    padding: 0 10px;
    flex: 1 1 calc(~"25% - 10px");
  }
  @media(min-width: 1100px) {
    padding: 0 16px;
    max-width: 328px;
  }
}
.nav-inside__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px;
  width: 100%;
  background-color: @grisclair;
  border-radius: 48px;
  @media(min-width: 940px) {
    padding: 24px;
  }
  @media(min-width: 1100px) {
    padding: 36px;
  }
  @media(min-width: 1200px) {
    padding: 48px;
  }
}
.nav-inside__text {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  .basefont;
  font-size: 14px;
  line-height: 1.3;
  color: @majeure;
  @media(min-width: 940px) {
    margin-bottom: 15px;
  }
  @media(min-width: 1100px) {
    margin-bottom: 40px;
  }
}
.nav-inside__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 10px 20px 10px;
  background-color: @majeure;
  border-radius: 14px;
  cursor: pointer;
  @media(min-width: 600px) {
    padding: 15px 10px 10px 10px;
  }
  @media(min-width: 1100px) {
    padding: 25px 10px 20px 10px;
  }
  &:hover {
    background-color: @mineure;
  }
  .button__text {
    .heavyfont;
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    @media(min-width: 940px) {
      font-size: 16px;
    }
    @media(min-width: 992px) {
      font-size: 18px;
    }
  }
}
.button-direct-link__container {
  display: flex;
  margin: 16px 0;
  padding: 0 16px;
  width: 100%;
  max-width: 672px;
  flex-shrink: 0;
  @media(min-width: 500px) {
    padding: 0 10px;
  }
  @media(min-width: 768px) {
    padding: 0 16px;
    flex: 1 1 50%;
  }
  @media(min-width: 940px) {
    padding: 0 10px;
  }
  @media(min-width: 1100px) {
    padding: 0 16px;
  }
}
.button-direct-link__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 12px 16px;
  width: 100%;
  background-color: @majeure;
  border-radius: 14px;
  cursor: pointer;
  &:hover {
    background-color: @mineure;
  }
}
.button-direct-link__text {
  width: 100%;
  .basefont;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  color: white;
}
.button-direct-link__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 18px;
  height: 18px;
  .arrow-icon {
    width: 100%;
    height: 100%;
    fill: white;
  }
}
.help-sections__zone {
  width: 100%;
}
.bloc-subject__container {
  margin: 0 auto;
  padding: 16px 10px;
  width: 100%;
  max-width: 1312px;
  @media(min-width: 768px) {
    padding: 16px;
  }
  @media(min-width: 940px) {
    padding: 16px 10px;
  }
  @media(min-width: 1100px) {
    padding: 16px;
  }
}

.bloc-subject__inner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 48px 24px 48px 28px;
  width: 100%;
  background-color: white;
  border-radius: 14px;
  cursor: pointer;
  box-shadow: 0 5px 20px rgba(0,0,0,.2);
  transition: background-color 160ms @transition-easing;
  @media(min-width: 768px) {
    padding: 48px;
  }
}
.bloc-subject__container .button-direct-link__icon {
  position: absolute;
  right: 16px;
  bottom: 16px;
  .arrow-icon {
    fill: @majeure;
    transition: fill 160ms @transition-easing;
  }
}
.bloc-subject__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  svg {
    width: 100%;
    height: 100%;
    fill: @majeure;
    color: @mineure;
    transition: fill 160ms @transition-easing;
  }
}
.bloc-subject__texts {
  width: 100%;
  margin-left: 32px;
  @media(min-width: 768px) {
    margin-left: 48px;
  }
}
section[data-class='blocsubject-inline'] {
  .bloc-subject__texts {
    margin-left: 0;
  }
}
.bloc-subject__title {
  .chaney-reg;
  font-size: 22px;
  text-transform: uppercase;
  color: @majeure;
  transition: color 160ms @transition-easing;
  @media(min-width: 420px) {
    font-size: 26px;
  }
}
.bloc-subject__text {
  .basefont;
  font-size: 18px;
  color: @majeure;
  transition: color 160ms @transition-easing;
}
.bloc-subject__inner:hover, .bloc-subject__inner.active {
  background-color: @majeure;
  .bloc-subject__title,
  .bloc-subject__text {
    color: white;
  }
  .arrow-icon {
    fill: white;
    transition: transform 1s;
    transform: rotate(90deg);
  }
  .bloc-subject__icon svg {
    fill: white;
  }
}
div.iframe-container {
  overflow: hidden;
  position: relative;
  /* Depend on iframe content */
  /* 16:9 Youtube video */
  padding-top: 56%;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.bloc-subject__texts div.iframe-container {
  /* Depend on form content */
  padding-top: 71%;
  &.iframe-container--sales {
    /* Depend on form content */
    padding-top: 102%;
  }
  &.iframe-container--assistance {
    /* Depend on form content */
    padding-top: 125%;
  }
  &.iframe-container--crash {
    /* Depend on form content */
    padding-top: 128%;
  }
  &.iframe-container--inquiry {
    /* Depend on form content */
    padding-top: 102%;
  }
}
.cms--editing .nav-inside__container {
  width: 25%;
}
