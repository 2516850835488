body:not(.cms--connected):not(.cms--editing):not(.cms--info) section[data-hidden='true'] {
  display: none !important;
}
.cms--editing section[data-hidden='true'],
.cms--info section[data-hidden='true'],
.cms--connected section[data-hidden='true'] {
  position: relative;
}

.cms--editing section[data-hidden='true']::after,
.cms--info section[data-hidden='true']::after,
.cms--connected section[data-hidden='true']::after {
  content: '' !important;
  position: absolute !important;
  top: -6px !important;
  left: -6px !important;
  width: calc(100% + 12px) !important;
  height: calc(100% + 12px) !important;
  background-color: rgba(231,132,130, 0.15) !important;
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 12.5%, transparent 0, transparent 50%, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0.1) 62.5%, transparent 0, transparent) !important;
  background-size: 5.66px 5.66px;
  border-radius: 8px !important;
  z-index: 1 !important;
  pointer-events: none !important;
  touch-action: none !important;
}