//.gmnoprint,
.gmnoscreen,
.gm-fullscreen-control,
.gm-style-cc,
.gm-style-pbc,
.gm-style-pbt,
.gm-style>div>a {
  display: none!important;
}

.contenu-map {
  position: relative;
  padding-top: 0;
}

.map-overlay {
  display: none;
  @media (min-width: @screen-sm) {
    display: initial;
    position: absolute;
    top: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    touch-action: none;
    .titresection {
      margin: 0 auto;
      padding-top: 80px;
      //padding-left: 100px;
      text-align: left;
      color: white;
    }
  }
}
.touch .map-overlay {
  display: none;
}
.touch .map-before, .touch .map-after {
  display: block;
}
@media (min-width: @screen-sm) {
  .map-before, .map-after {
    display: none;
  }
}
.bloc-address {
  margin: 0 auto;
  max-width: @body-width;
  > div {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 240px;
    padding: 20px;
    background-color: white;
    @media(min-width: @screen-md) {
      max-width: 500px;
    }
  }
}
.contenu-map .map-after .bloc-address > div {
  position: initial;
  max-width: initial;
  padding: 0;
}

.contenu-map .map-after .bloc-address {
  .company-name {
    padding-bottom: 0;
  }
  .company-addr {
    padding-bottom: 15px;
  }
}
.company-name {
  padding-bottom: 20px;
  .boldfont;
  .fs-28;
  color: @majeure;
  @media(min-width: @screen-md) {
    padding-bottom: 50px;
    .fs-36;
  }
}
.company-addr {
  .basefont;
  .fs-18;
  line-height: 30px;
  color: @grismoyen;
  @media(min-width: @screen-md) {
    padding-bottom: 30px;
    .fs-20;
  }
  i {
    font-style: normal;
    color: @majeure;
  }
}
.contenu-supp {
  padding-top: 0;
}