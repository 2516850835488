/************************************************************************/
/****************************** CONTACT ******************************************/
/************************************************************************/
input, textarea {
  max-width: 100%;
}

.btn.btn-danger {
  &.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    margin-top: 0;
  }
  .bookfont;
  line-height: 26px;
  font-size: 14px;
  color: white;
  background-color: @majeure;
}

.contenu.navfixed form .col {
  position: relative;
}

form .glyphicon {
  color: @grisclair;
}

.left-inner-addon {
  position: relative;
}

.left-inner-addon input {
  padding-left: 9px;
}

.left-inner-addon span {
  position: absolute;
  padding: 10px 12px;
  pointer-events: none;
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  //padding-right: 30px;
}

.right-inner-addon textarea {
  //padding-right: 30px;
}

.right-inner-addon span {
  position: absolute;
  right: 0;
  padding: 10px 12px;
  pointer-events: none;
}

.right-inner-addon.addon-area span {
  right: 15px;
}

.message {
  p {
    width: 100%;
    text-align: center;
    color: white;
    letter-spacing: 0.05em;
    border-radius: 5px;
    padding: 3px;
  }
  p.bg-success {
    background-color: green;
  }

  p.bg-danger {
    background-color: @majeure;
  }
}

.pull-right {
  text-align: right;
  margin-top: 13px;
  .adresse-customized {
    margin-top: 18px;
  }
  .addr {
    display: block;
  }
}

.contenu-contact {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: @gristitle;
  section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: 100%;
    max-width: @body-width;
    margin: 0 auto;
    @media(min-width: @screen-md) {
      flex-direction: row;
    }
    @media(min-width: 1300px) {
      padding: 0;
    }
  }
}
.col-address {
  margin: 0 15px;
  width: 100%;
  max-width: 400px;
  @media (min-width: @screen-md) {
    margin: initial;
  }
}
.col-form-contact {
  margin-left: 0;
  width: 100%;
  max-width: 1020px;
  @media(min-width: @screen-md) {
    margin-left: 50px;
    max-width: 780px;
  }
  .form-group {
    display: flex;
    margin-bottom: 0;
    flex-direction: column;
    width: 100%;
    @media(min-width: @screen-md) {
      margin-bottom: 15px;
      flex-direction: row;
      justify-content: space-between;
    }
    .col-xs-12 {
      @media(min-width: @screen-md) {
        padding-left: 20px;
        padding-right: 0;
      }
    }
  }
  .form-control, select {
    width: 100%;
    height: 40px;
    .basefont;
    .fs-24;
    color: @grisclair;
    background-color: white;
    border: none;
    border-radius: 0;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.3);

  }
  select {
    padding-left: 50px;
    padding-top: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/public/images/pictos/dropdown-arrow.svg');
    background-repeat: no-repeat;
    outline: 0;
    option {
      appearance: none;
      .fs-24;
      color: @grisclair!important;
    }
  }
  .col-xs-6 {
    padding-bottom: 10px;
    width: 100%;
    @media(min-width: @screen-md){
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 0;
      max-width: 500px;
    }
  }
  textarea.form-control {
    width: 100%;
    height: 100%;
    min-height: 120px;
  }
  .btn.btn-danger {
    display: block;
    padding: 8.5px 26px;
    .boldfont;
    .fs-20;
    color: white;
    background-color: @majeure;
    border-radius: 0;
    border: 0;
    outline: 0;
    transition: background-color 160ms @transition-easing;
    &:hover {
      background-color: @mineure;
    }
  }
  .right-inner-addon {
    margin-top: 20px;
    @media (min-width: @screen-md){
      margin-top: 0;
      padding-left: 20px;
      padding-right: 0;
    }
  }
}
address h3 {
  .boldfont;
  .fs-36;
  color: white;
  b {
    color: @majeure;
  }
}
.adresse-customized {
  span {
    display: block;
    .basefont;
    .fs-20;
    line-height: 30px;
    color: white;
    &.addr-mail {
      display: initial;
      padding: 7px 10px 3px 10px;
      background-color: @majeure;
    }
  }
}