.search__section {
  position: sticky;
  top: 80px;
  margin: 32px auto;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1312px;
  background-color: white;
  z-index: 2;
  white-space: nowrap;
  @media(min-width: 700px){
    padding: 5px 6px;
  }
  @media(min-width: 900px){
    padding: 10px 6px;
  }
  @media(min-width: 1200px){
    padding: 10px 0;
  }
}
#site-search {
  padding: 28px 24px 24px 24px;
  width: 100%;
  font-size: 20px;
  background-color: @grisclair;
  border: none;
  border-radius: 24px;
  @media(min-width: 768px) {
    font-size: 32px;
  }
  &:focus {
    outline: none;
  }
}
.search__text{
  position: relative;
  margin-bottom: 32px;
}
.search__text input[type="text"]::placeholder {
  color: @majeure;
}
.search__field {
  width: 100%;
}
.keywords__section {
  margin: 0 -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media(min-width: 768px) {
    flex-wrap: nowrap;
    margin: 0 -6px;
  }
  @media(min-width: 1200px) {
    margin: 0;
  }
}
.keyword-section {
  display: flex;
  padding: 0 5px;
  flex: 1 0 50%;
  @media(min-width: 600px) {
    padding: 0 6px;
    flex: 1 0 25%;
  }
  @media(min-width: 768px) {
    flex: 1 1 25%;
  }
  @media(min-width: 1200px) {
    padding: 0 16px;
  }
}
.keyword {
  display: flex;
  margin: 5px 0;
  flex: 1 0 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media(min-width: 600px) {
    margin: 5px 0;
    flex: 1 0 25%;
  }
  @media(min-width: 840px) {
    margin: 8px 0;
    flex: 1 1 auto;
    flex: 1 0 calc(~"50% - 16px");
    flex-basis: calc(~"50% - 16px");
    width: 100%;
    max-width: 288px;
  }
}
.keyword span,
.Keyword-actif {
  padding: 9px 0 5px 0;
  width: 100%;
  max-width: 288px;
  .boldfont;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: @majeure;
  border: 2px solid @majeure;
  border-radius: 14px;
  transition: color 160ms @transition-easing, background-color 160ms @transition-easing;
  @media(min-width: 600px) {
    padding: 12px 8px 8px 8px;
  }
  @media(min-width: 700px) {
    padding: 12px 8px 8px 8px;
    font-size: 18px;
  }
  &:hover {
    color: white;
    background-color: @majeure;
  }
}
.keyword span:active,
span.Keyword-actif {
  color: white;
  background-color: @majeure;
}
.Keyword-inactif {
  padding: 9px 0 5px 0;
  width: 100%;
  max-width: 288px;
  .boldfont;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: @majeure;
  border: 2px solid @majeure;
  border-radius: 14px;
  transition: color 160ms @transition-easing, background-color 160ms @transition-easing;
  @media(min-width: 600px) {
    padding: 12px 8px 8px 8px;
  }
  @media(min-width: 700px) {
    padding: 12px 8px 8px 8px;
    font-size: 18px;
  }
  &:hover {
    color: white;
    background-color: @majeure;
  }
}
.icon-search__container {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    .icon-search {
      fill: @mineure;
    }
  }
  .icon-search {
    width: 32px;
    height: 32px;
    fill: @majeure;
  }
}