.entete-software {
  display: flex;
  margin: 20px auto;
  padding: 10px 0 10px 0;
  width: 100%;
  max-width: @body-width;
  height: 100px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .basefont;
  .fs-16;
  line-height: 30px;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.2);
  @media(min-width: 768px) {
    padding: 5px 10px 20px 10px;
    height: 130px;
  }
  @media(min-width: 992px) {
    justify-content: center;
    .fs-24;
  }
  @media(min-width: 1200px) {
    padding: 15px 10px 20px 10px;
    .fs-30;
    line-height: 50px;
  }
}
.try .entete-software {
  display: block;
  padding: 20px 0 10px 0;

  height: 100px;
  text-align: center;
  background-color: @grismoyen;
  .fs-20;
  @media(min-width: @screen-xs) {
    padding: 35px 10px 20px 10px;
  }
  @media(min-width: @screen-md) {
    padding: 50px 10px 20px 10px;
    height: 130px;
    .fs-24;
  }
  @media(min-width: @screen-lg) {
    padding: 40px 10px 20px 10px;
    .fs-30;
    line-height: 50px;
  }
  i {
    font-style: normal;
    color: @majeure;
  }
}
.get-license .entete-software {
  background-color: @majeure;
  ul {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: row;
    padding: 0 10px 0 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media(min-width: @screen-xs) {
      justify-content: center;
    }
    @media(min-width: @screen-sm) {
      padding: 0 30px 0 30px;
    }
    @media(min-width: @screen-md) {
      padding: 0 50px 0 50px;
    }
    li {
      white-space: nowrap;
      margin-left: 15px;
      line-height: 20px;
      @media(min-width: @screen-sm) {
        line-height: 30px;
        margin-left: 25px;
      }
      @media(min-width: 1300px) {
        line-height: 50px;
      }
    }
  }
}
section[data-class="bloclicense"] {
  margin: 0 auto;
  width: 100%;
  max-width: @body-width;
}
.license-outer a {
  display: flex;
  flex-direction: column;
  margin-left: -10px;
  margin-right: -10px;
  align-items: center;
  @media(min-width: 768px) {
    flex-direction: row;
    align-items: initial;
  }
}
.license-inner {
  margin: 0 10px 40px 10px;
  img {
    filter: drop-shadow(0 12px 14px rgba(0, 0, 0, 0.2));
    cursor: pointer;
  }
}
.trial-outer {
  display: flex;
  flex-direction: column;
  margin: 40px auto 30px;
  width: 100%;
  max-width: @body-width;
  justify-content: space-between;
  @media(min-width: 992px) {
    flex-direction: row;
  }
  .txt-press {
    margin-top: 50px;
    width: 100%;
    max-width: 750px;
    @media (min-width: 992px) {
      margin-top: 0;
    }
  }
  .trial-dl-outer {
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    order: 0;
    @media (min-width: 992px) {
      margin-left: 30px;
    }
  }
  .listing {
    flex-direction: column;
    padding-left: 20px;
    @media(min-width: @screen-sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .listing > div {
    margin-left: 0;
    width: 100%;
    @media(min-width: @screen-sm) {
      padding-left: 10px;
      width: 40%;
    }
    @media(min-width: @screen-lg) {
      width: 30%;
    }
    li {
      color: @gristitle;
    }
  }
}
.bloc-buy .trial-outer {
  align-items: center;
  .txt-press {
    height: auto;
    @media (min-width: @screen-md) {
      margin: 0 30px;
    }
    @media (min-width: 1300px) {
      margin: 0 auto;
    }
  }
  .trial-dl-outer {
    @media (min-width: @screen-md) {
      margin: 0;
    }
  }
}