#signupform {
  width: 100%;
  .input-group {
    width: 100%;
  }
  input {
    width: 80%;
    border: 0.25px solid rgba(0,0,0,.075);
  }
}
#signupform .radio-inline input {
  width: auto;
}
.form-horizontal--step2 .control-label {
  padding-left: 0;
  text-align: left;
}
.form-horizontal .login-username {
  padding-top: 14px;
  padding-right: 10px;
}
.control a {
  color: @mineure;
  &:hover {
    color: @majeure;
  }
}
.form-horizontal .radio-inline {
  margin-left: 20px;
  padding-top: 0;
}
.form-horizontal .control-label.pwd__label {
  width: 160px;
}
.form-horizontal .control-label.pwd__label--2 {
  width: 128px;
}
#signupform input.pwd__entry {
  width: 200px;
}