
#request__subject-field {
  padding: 20px 24px 16px 24px;
  width: 100%;
  font-size: 20px;
  background-color: @grisclair;
  border: none;
  border-radius: 24px;
  @media(min-width: 768px) {
    font-size: 32px;
  }
}
.request__subject{
  position: relative;
  margin-bottom: 32px;
}
.request__subject input[type="text"]::placeholder {
  color: @majeure;
}
input[required]:invalid + label {
  display: inline-block;
}

/* hide the placeholder when input has some text typed in */
input[required]:valid + label{
  display: none;
}
.request__subject input[type="text"] + label {
  position: absolute;
  top: 20px;
  left: 24px;
  font-size: 20px;
  color: @majeure;
  pointer-events: none;
  touch-action: none;
  @media(min-width: 768px) {
    font-size: 32px;
  }
  &:after {
    content:'*';
    color: @mineure;
  }
}

.col-form-request {
  margin-top: 70px;
  margin-left: 0;
  width: 100%;
  max-width: 1020px;
  @media(min-width: @screen-md) {
    margin-left: 50px;
  }
  .form-group {
    display: flex;
    margin-bottom: 0;
    flex-direction: column;
    width: 100%;
    @media(min-width: @screen-md) {
      margin-bottom: 15px;
      flex-direction: row;
      justify-content: space-between;
    }
    .col-xs-12 {
      @media(min-width: @screen-md) {
        padding-left: 20px;
        padding-right: 0;
      }
    }
  }
  .form-control, select {
    width: 100%;
    height: 40px;
    .basefont;
    .fs-24;
    color: @grisclair;
    background-color: white;
    border: none;
    border-radius: 0;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.3);

  }
  select {
    padding-left: 50px;
    padding-top: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/public/images/pictos/dropdown-arrow.svg');
    background-repeat: no-repeat;
    option {
      appearance: none;
      .fs-24;
      color: @grisclair!important;
    }
  }
  .col-xs-6 {
    padding-bottom: 10px;
    width: 100%;
    @media(min-width: @screen-md){
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 0;
      max-width: 500px;
    }
  }
  textarea.form-control {
    width: 100%;
    height: 100%;
    min-height: 120px;
  }
  .btn.btn-danger {
    display: block;
    padding: 8.5px 26px;
    .boldfont;
    .fs-20;
    color: white;
    background-color: @majeure;
    border-radius: 0;
    border: 0;
  }
  .right-inner-addon {
    margin-top: 20px;
    @media (min-width: @screen-md){
      margin-top: 0;
      padding-left: 20px;
      padding-right: 0;
    }
  }
}