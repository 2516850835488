/******************************* DIAPORAMA **************************************/
#home-slide-featured {
  padding-right: 10px;
  padding-left: 10px;
  background-color: @grisclair;
  @media(min-width: 1300px) {
    padding-right: 0;
    padding-left: 0;
  }
}
#home-slide-featured .titresection {
  text-align: center;
  @media(min-width: 992px){
    text-align: right;
  }
}
.slider-container {
  position: relative;
  width: 100%;
  @media(min-width: 768px) {
    padding-top: 30px;
  }
  .flexslider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    min-height: 880px;
    overflow: hidden;
    @media (min-width: 500px) {
      min-height: 850px;
    }
    @media (min-width: 768px) {
      min-height: 870px;
    }
    @media(min-width: 892px) {
      min-height: 500px;
    }
    @media(min-width: 992px) {
      min-height: 490px;
    }
  }
}
.slider-container .slider-outer {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width : 100%;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  //overflow: hidden;
  transition: transform 320ms @transition-easing;
  @media(min-width: 992px) {
    min-height: 490px;
    max-width: @body-width;
    align-items: center;
  }
}
.slide {
  position: absolute;
  display: flex;
  width: 100%;
  height: auto;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  @media (min-width: 892px) {
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
  @media (min-width: 992px) {
    align-items: center;
  }
  .slider-img-container {
    width: 100%;
    max-width: 380px;
    order: 1;
    @media (min-width: 500px) {
      max-width: none;
    }
    @media (min-width: 620px) {
      max-width: 600px;
    }
    @media (min-width: 892px) {
      order: 0;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.slide .slider-desc-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 350px;
  margin-bottom: 24px;
  order: 0;
  @media (min-width: 500px) {
    max-width: none;
  }
  @media (min-width: 892px) {
    margin-bottom: 0;
    padding-left: 20px;
    flex: 0 0 55%;
    order: 1;
  }
  @media (min-width: 992px) {
    margin-left: 30px;
    height: 100%;
  }
  @media (min-width: 1200px) {
    margin-left: 40px;
    flex: 0 0 50%;
  }
}
.slider-desc-title {
  margin-top: 10px;
  .basefont;
  font-size: 28px;
  line-height: 1.2;
  color: @majeure;
  @media(min-width: 992px) {
    font-size: 36px;
  }
  &:empty {
    display: none;
  }
}
.slider-desc-txt {
  margin-top: 30px;
  .basefont;
  font-size: 19px;
  text-align: justify;
  font-style: italic;
  line-height: 1.2;
  color: @majeure;
  @media(min-width: 768px) {
    font-size: 20px;
    text-align: left;
  }
  @media(min-width: 992px) {
    font-size: 24px;
  }
}
.slider-desc-signature {
  margin-top: 40px;
  @media(min-width: 1200px) {
    margin-top: 60px;
  }
  .slider-desc-s1 {
    .chaney-reg;
    font-size: 20px;
    text-transform: uppercase;
    color: @majeure;
  }
  .slider-desc-s2 {
    font-size: 16px;
    color: @majeure;
  }
}
.carousel {
  position: relative;
  overflow: hidden;
}
.sliding-featured-container {
  padding: 30px 5px;
  @media (min-width: 1230px) {
    padding: 40px 0 90px 0;
  }
}
.sliding-featured-container .arrow-container--left,
.sliding-featured-container .arrow-container--right {
  display: none;
}
.sliding-featured-inner {
  margin-top: 60px;
}
.sliding-featured-inner {
  position: relative;
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    align-items: center;
  }
}
#home-diaporama {
  padding: 0 10px;
}
#home-diaporama .button-editable {
  text-align: center;
  @media(min-width: 992px){
    text-align: right;
  }
}
#home-diaporama .titresection {
  @media(min-width: 992px){
    text-align: left;
  }
}

.dots__container {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 50;
}
.dots__list {
  list-style-type: none;
  display: flex;
}
.dot__element {
  display: block;
  margin: 0 16px;
  width: 16px;
  height: 16px;
  font-size: 0.6em;
  text-align: center;
  color: white;
  background-color: @majeure;
  opacity: 0.5;
  border-radius: 100%;
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
  }
  &.dot__element--active {
    opacity: 1;
  }
}

.cms--editing .slider-container .flexslider {
  overflow: visible;
}
.cms--editing .slider-container .slider-desc-title:empty {
  display: block;
  min-width: 90%;
}