.faq__wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto 120px auto;
  padding: 0 10px;
  max-width: 1280px;
  @media(min-width: 992px) {
    flex-direction: row;
  }
  @media(min-width: 1300px) {
    padding: 0;
  }
}
.faq__left-column {
 // display: none;
 // @media(min-width: 768px){
    display: block;
    margin-top: 32px;
    margin-bottom: 30px;
    padding-left: 10px;
    width: 30%;
 // }
}
.faq-left__container {
  position: sticky;
  top: calc(~"50% - 80px");
}
.faq__main {
  @media(min-width: 768px){
    width: 70%;
  }
}
.faq__main section:not(.aside-faq) {
  margin-left: 10px;
  margin-top: -80px;
  padding-top: 80px;
}
.faq-outer {
  @media (min-width: 992px) {
    margin-top: 45px;
  }
}
.blocfaq {
  position: relative;
}
.faq__icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  z-index: 2;
  .faq-icon {
    width: 15px;
    height: 15px;
    fill: @mineure;
    z-index: 2;
  }
}
.aside-faq {
  display: flex;
  flex-direction: row;
  width: 100%;
  // margin-top: 10px;
  margin-bottom: 20px;
  align-items: flex-start;
  height: auto;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  //line-height: 1.3;
  border-radius: 10px 10px 10px 0;
  &:hover {
    .faq__question-inside {
      color: @mineure;
    }
  }
  &.show-reponse {
    display: block;
  }
}
.faq__question {
  display: flex;
  // margin-bottom: 15px;
  padding-top: 3px;
  padding-left: 5px;
  align-items: center;
  cursor: pointer;
  .boldfont;
  font-size: 18px;
  color: @majeure;
  @media (min-width: 992px) {
    width: 100%;
  }
}
.faq__question-inside {
  .boldfont;
  font-size: 18px;
  line-height: 1.1;
  color: @majeure;
  transition: color 160ms @transition-easing;
}
.faq__answer {
  height: 0;
  padding-left: 30px;
  .bookfont;
  font-size: 16px;
  text-align: justify;
  color: @majeure;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0ms, opacity 0ms @transition-easing;
  overflow: hidden;
}
.faq__answer.faq__answer--visible {
  height: auto;
  padding: 10px 0 0 5px;
  opacity: 1;
  transform: translateY(0);
  transition: transform 160ms, opacity 160ms @transition-easing;
  overflow: auto;
}
.page .faq__left-column .letexte {
  padding-bottom: 10px;
  .boldfont;
  font-size: 15px;
  a {
    color: @majeure;
    &:hover {
      color: @mineure;
    }
  }
}
.cms--editing .faq__answer {
  height: auto;
  padding: 10px 0 0 5px;
  opacity: 1;
  transform: translateY(0);
  transition: transform 160ms, opacity 160ms @transition-easing;
  overflow: auto;
}
.cms--editing .faq__main section:not(.aside-faq) {
  margin-top: 0;
  padding-top: 0;
}