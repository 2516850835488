
// ---------------------- MULTI SLIDING ---------------------- //
.slider-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  width: 55px;
  height: 55px;
  cursor: pointer;
  &:hover {
    .icon-arrow {
      fill: @mineure;
    }
  }
}
.icon-arrow {
  width: 25px;
  height: 25px;
  fill: @grisbleu;
  transition: fill 160ms @transition-easing;
  @media(min-width: 340px) {
    width: 35px;
    height: 35px;
  }
  @media(min-width: 500px) {
    width: 100%;
    height: 100%;
  }
}
.multislide__container {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
}
.multislide__wrapper {
  margin: 0;
  @media(min-width: 340px) {
    margin: 0 8px;
  }
  @media(min-width: 400px) {
    margin: 0 20px;
  }
  @media(min-width: 750px) {
    margin: 0 15px;
    width: 100%;
  }
  @media(min-width: 940px) {
   // margin: 0 10px;
    margin: 0 -8px;
  }
  @media(min-width: 992px) {
    margin: 0 auto;
  }
  @media(min-width: 1120px) {
    margin: 0 -10px;
  }
  @media(min-width:1440px) {
    margin: 0 -16px;
  }
}
.multislide-outer {
  display: flex;
  margin: 0 auto 50px auto;
  max-width: 230px;
  align-items: stretch;
  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  @media(min-width: 340px) {
    max-width: 250px;
  }
  @media(min-width: 420px) {
    max-width: 290px;
  }
  @media(min-width: 500px) {
    max-width: 296px;
  }
  @media(min-width: 650px) {
    //max-width: 500px;
  }
  @media(min-width: 750px) {
    max-width: 570px;
  }
  @media(min-width: 880px) {
    // max-width: 730px;
  }
  @media(min-width: 940px) {
    max-width: 795px;
    overflow: hidden;
  }
  @media(min-width: 1040px) {
    max-width: 825px;
  }
  @media(min-width: 1120px) {
    max-width: 918px;
  }
  @media(min-width: 1280px) {
    max-width: 1064px;
  }
  @media(min-width:1340px) {
    max-width: 1184px;
  }
  @media(min-width:1440px) {
  //  margin: 0 -16px;
    max-width: 1280px;
  }
}
.multi-slide {
  padding: 0 5px;
  width: 100%;
  max-width: 250px;
  min-height: 385px;
  flex-shrink: 0;
  scroll-snap-align: start;
  @media(min-width: 420px) {
    max-width: 290px;
  }
  @media(min-width: 500px) {
    padding: 0 10px;
    max-width: 296px;
  }
  @media(min-width: 650px) {
    max-width: 296px;
  }
  @media(min-width: 750px) {
    max-width: 285px;
  }
  @media(min-width: 940px) {
    padding: 0 5px;
    max-width: 265px;
  }
  @media(min-width: 1040px) {
    padding: 0 10px;
    max-width: 275px;
  }
  @media(min-width: 1120px) {
    max-width: 306px;
  }
  @media(min-width: 1280px) {
    max-width: 266px;
  }
  @media(min-width: 1340px) {
    max-width: 296px;
  }
  @media(min-width: 1440px) {
    margin: 0 16px;
    padding: 0;
    max-width: 296px;
  }
}
.multi-slide__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 385px;
  border-radius: 14px;
  overflow: hidden;
  background: rgb(59,59,78);
  background: linear-gradient(180deg, rgba(59,59,78,0) 0%, rgba(59,59,78,0.5032387955182073) 25%, rgba(59,59,78,1) 55%);

}
.multi-slide__data {
  // position: relative;
  display: block;
  padding: 0 15px 0 30px;
  height: 50%;
  @media(min-width: 540px) {
    padding: 0 15px 0 20px;
  }
  @media(min-width: 768px) {
    padding: 0 15px 0 30px;
  }
}
.multi-slide__picture-container {
  position: relative;
  overflow: visible;
}
.multi-slide__title {
  position: absolute;
  padding: 0 15px 0 30px;
  top: 195px;
  .chaney-reg;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
  color: white;
  @media(min-width: 540px) {
    padding: 0 15px 0 20px;
    top: 175px;
  }
  @media(min-width: 768px) {
    padding: 0 15px 0 30px;
    top: 175px;
  }
}
.multi-slide__text {
  position: absolute;
  top: 300px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 1.2;
  color: white;
  @media(min-width: 540px) {
    top: 290px;
  }
  @media(min-width: 768px) {
    top: 300px;
  }
}
.tagline__date {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 10px;
  text-align: right;
  color: @grisbleu;
}
.multi-slide__label {
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 5px 6px 2px 6px;
  .boldfont;
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  background-color: @mineure;
  border-radius: 5px;
}



// ********* FEATURES SLIDING ************ //
.features__zone {
  padding-top: 80px;
}
.sliding-features-inner {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  justify-content: space-between;
  align-items: center;
}
.features-slider {
  display: flex;
  //margin: 0 auto 50px auto;
  margin-bottom: 50px;
  max-width: 300px;
  align-items: stretch;
  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  @media(min-width: 500px) {
    max-width: 390px;
  }
  @media(min-width: 600px) {
    max-width: 427px;
  }
  @media(min-width: 768px) {
    max-width: 620px;
  }
  @media(min-width: 880px) {
    max-width: 780px;
  }
  @media(min-width: 992px) {
    margin-top: 40px;
    max-width: 840px;
    overflow: hidden;
  }
  @media(min-width: 1020px) {
    max-width: 850px;
  }
  @media(min-width: 1120px) {
    margin-top: 70px;
    max-width: 1020px;
  }
  @media(min-width: 1200px) {
    max-width: 1050px;
  }
  @media(min-width: 1280px) {
    max-width: 1080px;
  }
  @media(min-width:1300px) {
    max-width: 1200px;
  }
  @media(min-width:1400px) {
    max-width: 1311px;
  }
}

.feature-slide {
  padding: 0 5px;
  width: 100%;
  max-width: 300px;
  min-height: 696px;
  flex-shrink: 0;
  scroll-snap-align: start;
  @media(min-width: 500px) {
    padding: 0 11px;
    max-width: 390px;
  }
  @media(min-width: 600px) {
    max-width: 427px;
  }
  @media(min-width: 768px) {
    padding: 0 6px;
    max-width: 310px;
  }
  @media(min-width: 880px) {
    padding: 0 10px;
    max-width: 390px;
  }
  @media(min-width: 992px) {
    padding: 0 10px;
    max-width: 420px;
  }
  @media(min-width: 1020px) {
    padding: 0 10px;
    max-width: 425px;
  }
  @media(min-width: 1120px) {
    padding: 0 5px;
    max-width: 340px;
  }
  @media(min-width: 1200px) {
    padding: 0 11px;
    max-width: 350px;
  }
  @media(min-width: 1280px) {
    padding: 0 11px;
    max-width: 360px;
  }
  @media(min-width: 1300px) {
    padding: 0 6px;
    max-width: 400px;
  }
  @media(min-width: 1400px) {
    padding: 0 16px;
    max-width: 437px;
  }
}
.feature-slide__inner .multi-slide__data {
  position: relative;
  min-height: 440px;
  padding: 0 10px;
  @media(min-width: 340px) {
    padding: 0 25px;
  }
  @media(min-width: 500px) {
    padding: 0 50px;
  }
}
.feature-slide__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 696px;
  border-radius: 14px;
  overflow: hidden;
  background-color: @majeure;
}
.feature-slide__inner .multi-slide__title {
  position: relative;
  top: 0;
  padding: 40px 0 0 0;
}
.feature-slide__inner .multi-slide__text {
  top: 130px;
  .bookfont;
  font-size: 16px;
  @media(min-width: 400px){
    font-size: 18px;
  }
}
.feature__link {
  position: absolute;
  display: block;
  text-align: center;
  bottom: 25px;
  left: 50px;
  width: calc(~"100% - 100px");
  cursor: pointer;
  @media(min-width: 400px) {
    bottom: 45px;
  }
  &:hover {
    .feature__button {
      color: @mineure;
      border: 2px solid @mineure;
    }
  }
}
.feature__button {
  padding: 18px 15px 12px 15px;
  .boldfont;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  transition: color 160ms @transition-easing, border 160ms @transition-easing;
  &:empty {
    border: none;
  }
}
// SURCHARGE HOMEPAGE

#home-diaporama .multi-slide__data {
  padding: 0 15px 0 20px;
  @media(min-width: 360px) {
    padding: 0 15px 0 30px;
  }
}


.cms--editing .feature__button:empty {
  border: 2px solid white;
}
.cms--editing .features-slider,
.cms--editing .multislide-outer,
.cms--editing .multi-slide__inner {
  overflow: visible;
}
.cms--editing .flexslider__container .arrow-container {
  z-index: 0;
}